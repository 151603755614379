<template>
  <div class="retour" @click="retour_push">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="18" height="18" viewBox="0 0 24 24"><path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" /></svg>
    Retour
  </div>
</template>

<script>
export default {
  name: "retour",
  props: [ 'name' ],
  methods: {
    retour_push() {
      this.$router.push({ name: this.name })
    }
  }
}
</script>

<style scoped>
  .retour { background: #373e50; color: var(--white-sub); fill: var(--white-sub); display: inline-flex; padding: 8px 12px 6px; border-radius: 4px; font-size: 16px; align-items: center; margin-bottom: 16px;
    margin-top: 5px; margin-left: 16px; cursor: pointer;
  }
  .retour svg { position: relative; top: -2px; margin-right: 4px; }


  @media only screen and (min-width: 800px) {
    .retour { margin-left: 0; }
  }
  
  
</style>