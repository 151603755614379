<template>
  <div class="button_plus" :class="[ { fw: fw }, [ color ] ]" :data-rounded="rounded">
    <button :disabled="disabled_f" @click="click_button">
      <span>
        <i v-if="loading_etat === true" class="el-icon-loading"></i>
        <span class="txt">{{ txt_show }}</span>
      </span>
      <span class="txt_sub">{{ txt_sub }}</span>
    </button>
  </div>
</template>

<script>
export default {
  // name: "button_plus",
  data() {
    return {
      disabled_f: false,
      loading_etat: false,
      txt_show: ''
    }
  },
  props: [ 'color', 'txt', 'txt_sub', 'txt_load', 'fw', 'disabled', 'load_ok', 'rounded', 'click_not_can' ],
  watch: {
    disabled() {
      console.log('disabled changed', this.disabled)
      this.disabled_f = this.disabled
      if(this.disabled === false) {
        this.loading_etat = false
      }
    },
    load_ok() {
      this.loading_etat = false
      this.disabled_f = false
      this.txt_show = this.txt
    }
  },
  mounted() {
    this.disabled_f = this.disabled
    this.loading_etat = false
    this.txt_show = this.txt
    this.$forceUpdate()
  },
  methods: {
    click_button() {
      if(!this.click_not_can) {
        this.loading_etat = true
        this.disabled_f = true
        
        if(this.txt_load)
          this.txt_show = this.txt_load
      }
      this.$emit('success')
    }
  }
}
</script>

<style scoped>
  .button_plus { }
  .button_plus button { background: var(--linear-vert); display: flex; flex-direction: column; align-items: center; justify-content: center; }
  .button_plus button .txt_sub { font-size: 14px }
  
  .button_plus.green button { background: var(--linear-vert) }
  .button_plus.green_simple button { background: var(--vert-button) }
  .button_plus.orange button { background: var(--linear-orange) }
  .button_plus.stripe button { background: #6772e5 }
  
  .button_plus.fw { display: flex; width: 100%; }
  .button_plus.fw  button { width: 100%; }
  
  .button_plus button[disabled] { background: #ddd; color: var(--boColor-gris2) }
  .button_plus button i { margin-right: 4px; }
  
  .button_plus[data-rounded] button { border-radius: 24px }
</style>