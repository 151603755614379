// import router from "../../router";
import axios from 'axios'

const state = {
    logged: false,
    user: {}
};

// getters
const getters = {
    getUser: state => state.user
};

// actions
const actions = {
    init(store) {
        let token = localStorage.getItem("token");
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`
            axios.post('/user/auth_by_key', { key: token }).then((data) => {
                // this.$store.dispatch("user/login", data.data);
                store.commit('LOGIN', data.data)
                
                // if(data.data.user.boutiques.length)
                //     localStorage.setItem("boutique_bo", data.data.user.boutiques[0])
                // else
                //     localStorage.removeItem("boutique_bo")
                
                // this.$root.$gener_boutique_id(data.data.user)
            }).catch((e) => {
                if(e.response.status && e.response.status === 401) {
                    localStorage.removeItem('token')
                    // location.reload()
                }
            })
        }

    },
    login(store, data) {
        // this.axios.defaults.headers.common['Authorization'] = data.token
        console.log(data)
        if(data.token) {
            store.commit("LOGIN", data);
            axios.defaults.headers.common['Authorization'] = `Bearer ${ data.token }`
            localStorage.setItem("token", data.token);
    
            // if(data.user.boutiques.length)
            //     localStorage.setItem("boutique_bo", data.user.boutiques[0])
            // else
            //     localStorage.removeItem("boutique_bo")
            
        }
    },
    beforeLogin(store, name) {
        localStorage.setItem("beforeLogin", name);
    }
};

// mutations
const mutations = {
    LOGIN(state, data) {
        state.user = data.user;
        state.logged = true;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
