<template>
  <div class="title" :class="[ { clickable: clickable }, [ size ] ]" :data-fw="fw" @click="click_title">
    <div class="int">
      <slot></slot>
      <i v-if="clickable" :class="'el-icon-' + icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "tite",
  data() {
    return {
      
    }
  },
  props: [ 'clickable', 'link', 'icon', 'size', 'fw' ],
  methods: {
    click_title() {
      if(this.link)
        this.$router.push(this.link)
      
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
  .title { margin-bottom: 16px; height: 40px;  }
  .title .int { display: inline-flex; align-items: center; height: 100%; font-size: 18px; font-weight: bold; color: var(--boColor-gris1); transition: all .08s ease-in-out }
  .title[data-fw] .int { width: 100%; display: flex; justify-content: space-between; }
  .title.clickable .int:hover { border: 1px solid var(--boColor-gris3); border-radius: 50px; padding: 0 16px; cursor: pointer; }
  .title.clickable .int i { padding-left: 8px; }
  
  .title.big { height: inherit; }
  .title.big .int { align-items: flex-start; overflow: hidden; font-size: 14px; line-height: 30px; font-weight: 400; padding: 6px 0; color: var(--boColor-gris2); white-space: pre; background: #f5f5f5; padding: 6px 16px }
  .title.big .int i { padding-left: 16px; padding-top: 6px; font-size: 20px }
  .title.big.clickable .int:hover { border-radius: 0px; border: none; padding: 6px 16px; border-left: 6px solid var(--orange); background: #f5f5f5 }


  @media only screen and (min-width: 800px) {
    /*.title { padding-left: 0; height: 40px; align-items: center; display: flex; }*/
    /*.title span { padding-left: 4px; }*/
  }

  
</style>