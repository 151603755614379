<template>
  <div class="input_solo fullscreen">
    <div id="search_opaque" @click="$emit('close')"></div>
    <div class="input" id="inp">
<!--      <div class="ico_main">-->
<!--        <i class="el-icon-plus"></i>-->
<!--      </div>-->

      <form @submit.prevent="result">
        <input :type="type" :placeholder="label" v-if="type === 'number'" v-model="input" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
        <input :type="type" :placeholder="label" v-else v-model="input">
      </form>
      
      <div class="ico_validate" @click="result">
        <i class="el-icon-circle-check"></i>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  name: "input_solo",
  data() {
    return {
      input: null
      
    }
  },
  props: [ 'label', 'type' ],
  created() {
    setTimeout(function() {
      document.querySelector('.input_solo input').focus()
    })
  },
  methods: {
    result() {
      if(this.input && this.input.length > 0) {
        this.$emit('result', this.input)
      }
    }
  }
}
</script>

<style scoped>
.input_solo { background: #fff; width: 100%; margin-bottom: 16px; display: flex; align-items: center; position: relative;
  flex-direction: column; justify-content: center; z-index: 99;
}
.input_solo .input { display: flex; align-items: center; width: 100%; background: #eee; position: relative; }
.input_solo .input .ico_main { margin: 0 16px; position: absolute; top: 0; bottom: 0; display: flex; align-items: center }
.input_solo .input .ico_validate { margin: 0 16px; position: absolute; top: 0; bottom: 0; right: 0; display: flex; align-items: center; font-size: 22px; cursor: pointer; }
.input_solo .input form { width: 100%; }
.input_solo .input input { width: 100%; height: 50px; border: none; background: none; outline: none; padding-right: 50px; padding-left: 16px; transition: all .05s ease-in-out }
.input_solo .input.search_etat:after { border-bottom: 2px solid #111; position: absolute; bottom: 0; left: 0; right: 0; content: '' }

.input_solo .result_list { position: absolute; top: 50px; background: #fff; width: 100%; max-height: 40vh; overflow-y: auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); border-radius: 0 0 4px 4px;
  overflow-x: hidden;
}
.input_solo .result_list .e { display: flex; align-items: center; height: 76px; width: 100%; cursor: pointer }
.input_solo .result_list .e:hover { background: #f5f5f5 }
.input_solo .result_list .e .ico { width: 36px; height: 36px; display: flex; align-items: center; justify-content: center; margin: 0 11px }
.input_solo .result_list .e .ico svg { margin: 0 }
.input_solo .result_list .e .txt .ville { font-size: 16px; color: var(--boColor-gris1); padding-bottom: 1px; }
.input_solo .result_list .e .txt .details { font-size: 14px; color: var(--boColor-gris2) }

.input_solo.fullscreen { position: fixed; top: 72px; right: 0; bottom: 0; left: 0; z-index: 100; max-width: inherit!important; margin: 0!important; padding: 0; background: none;
  justify-content: flex-start;
}
.input_solo.fullscreen #search_opaque { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(20,20,20,.8) }
.input_solo:not(.fullscreen) #search_opaque { display: none }

@media only screen and (min-width: 800px) {
  .input_solo.fullscreen { padding: 0 35%; justify-content: center; top: 0; }
  .input_solo.fullscreen .input { border-radius: 1px }
  .input_solo.fullscreen .input input { height: 60px; }
  .input_solo.fullscreen .result_list { top: 60px; }
}

</style>