<template>
  <div id="app" :class="{ menu_active: menu_etat }" :data-nav_hide_mobile="$route.meta.nav_hide_mobile" :data-load="$load" :data-loader="$route.meta.loader">

    <header class="header" v-if="!$route.meta.bo">
      <div class="left">
        <div class="burger" @click="menu_etat = !menu_etat">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></svg>
        </div>

        <router-link tag="div" :to="{ name: 'Home' }" class="marque"><span class="">MonResto</span><span class="click">.click</span></router-link>
      </div>

      <div class="right">

        <transition name="bounce" appear>
        <router-link v-if="($route.name === 'boutique' || $route.name === 'boutique_locality' || $route.name === 'boutique_id' || $route.name === 'boutique_key' || $route.name === 'produit') && $panier_indicator" tag="div" :to="{ name: 'panier', params: { boutique_id: $boutique_id } }" class="panier_txt">Panier</router-link>
        </transition>
        <transition name="bounce" appear>
        <router-link v-if="($route.name === 'boutique' || $route.name === 'boutique_locality' || $route.name === 'boutique_id' || $route.name === 'boutique_key' || $route.name === 'produit') && $panier_indicator" tag="div" :to="{ name: 'panier', params: { boutique_id: $boutique_id } }" class="panier_ico">{{ $panier_indicator }}</router-link>
        </transition>
        <router-link tag="div" class="me_connecter" :to="{ name: 'user_login' }" v-if="!$store.state.user.logged">Se connecter</router-link>
        
        
      </div>
      <div class="bar_top"></div>
    </header>


    
    <transition name="slide-fade">
    <div class="opaque" @click="menu_etat = false" v-if="menu_etat"></div>
    </transition>
      
      
    <div class="nav">
      
      
      <div v-if="!$store.state.user.logged">
        <div @click="menu_etat = false">
          <router-link :to="{ name: 'user_login' }" class="button">Se connecter</router-link>
        </div>
      </div>
      <div>
        <div @click="menu_etat = false" >
          <router-link tag="div" :to="{ name: 'user_compte_commandes' }" v-if="$store.state.user.logged" class="user_pic">
            <lottie-animation v-if="menu_etat === true" class="pic" :width="60" :height="60" :speed=".8" :loop="false" path="lottie/circled-user-male-skin-type-7.json" />
            <div class="right">
              <span>{{ $store.state.user.user.prenom }}</span>
              <span class="email">{{ $store.state.user.user.email }}</span>
            </div>
          </router-link>
        </div>

        <div @click="menu_etat = false" >
<!--        <div class="link bold"><i class="el-icon-location"></i>Changer de ville</div>-->
          <router-link tag="a" :to="{ name: 'user_compte_commandes' }" v-if="$store.state.user.logged" class="link bold"><i class="el-icon-s-goods"></i>Commandes</router-link>
          
          <div v-if="$store.state.user.logged" class="link deco" @click="logout">Déconnexion</div>
        </div>
      
        <div v-if="$store.state.user.logged && this.$store.state.user.user.boutique">
          <hr>
          <div class="title">Mon Resto</div>
          <div @click="menu_etat = false">
            <router-link tag="a" :to="{ name: 'boutique_id', params: { boutique_id: this.$store.state.user.user.boutique } }" class="link"><i class="el-icon-view"></i>Voir</router-link>
            <router-link tag="a" :to="{ name: 'bo_commandes_enattente' }" class="link"><i class="el-icon-s-operation"></i>Backoffice</router-link>
          </div>
        </div>
        
        <div v-if="$store.state.user.logged && $store.state.user.user.superbo" @click="menu_etat = false" class="superbo">
          <hr>
          <router-link tag="a" :to="{ name: 'superbo' }" class="link"><i class="el-icon-first-aid-kit"></i>Super BO</router-link>
        </div>

        <hr>

        <div @click="menu_etat = false" v-if="!this.$store.state.user.user.boutique">
          <router-link tag="div" :to="{ name: 'votreresto' }" class="link">Ajouter mon restaurant</router-link>
        </div>
      </div>
      
    </div>

<!--    <transition name="slide-fadeMain" mode="out-in">-->
    <main class="main">
       <router-view/>
    </main>
<!--    </transition>-->
    <loader></loader>
    <maj></maj>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import loader from "@/components/global/loader"
import maj from "@/components/maj"
export default {
  data() {
    return {
      menu_etat: false,
      boutique: null,
      user: null,
      
      // header_anim: false,
      // header_show: true
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {

        // let _this = this
        // this.header_anim = false
        // setTimeout(() => {
        //   _this.header_anim = true
        // }, 600)
        
        this.$loader = false
        if(to.meta.title)
          document.title = to.meta.title + ' - MonResto.click'
        else
          document.title = 'MonResto.click';
      }
    },
    getBoutique(n) {
      this.boutique = n
    },
    getUser(n) {
      this.user = n
      if(this.$store.state.user.user.boutique) {
        // this.$bo_boutique_id = parseInt(n.boutiques[0])
        this.$store.dispatch("boutique_bo/sync", this.$store.state.user.user.boutique);
      }
      // this.$gener_boutique_id(n)
    }
  },
  components: {
    LottieAnimation,
    loader,
    maj,
  },
  sockets: {
    connect() {
      console.log('Socket Connect')
    },
    wakeup() {
      console.log('wakeup')
      this.$socket.client.emit('wakeup', 'wakeup')
    }
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    })


    /**
     * Scroll Header
     */
    let headerScroll = 0
    let lastScroll = window.scrollY
    window.onscroll = function() {
      let up = lastScroll > this.scrollY
      let scroll = window.scrollY - lastScroll
      
      if(!up && headerScroll > -69 && window.scrollY > 0) {
        headerScroll  = headerScroll - scroll
        if(headerScroll < -69) headerScroll = -69
        if(window.scrollY <= 0) headerScroll = 0
      }
      else if(up && headerScroll < 0) {
        headerScroll  = headerScroll - scroll
        if(headerScroll > 0) headerScroll = 0
        if(window.scrollY < 0) headerScroll = 0
      }
      document.querySelector('header').style.top = headerScroll + 'px'
      lastScroll = window.scrollY
    }
    
  },
  methods: {
    burger_click() {
      
    },
    opaque_click() {
      
    },
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('bo_boutique_id')
      location.href = '/'
    },
    afterLeave () {
      this.$root.$emit('triggerScroll')
    },
    first_auth() {
      this.$http.get('/auth').then((data) => {
        this.$store.dispatch("user/login", data.data);
      })
    }
  },
  computed: {
    getBoutique() {
      return this.$store.state.boutique_bo.boutique
    },
    getUser() {
      return this.$store.state.user.user
    }
  },
  created() {
    // let _this = this
    // let timeout = false;
    // if(localStorage.getItem('boutique_bo'))
    //   this.$bo_boutique_id = localStorage.getItem('boutique_bo')
    
    
    // let token = localStorage.getItem("token");
    // if (token) {
    //   this.$http.defaults.headers.common['Authorization'] = `Bearer ${ token }`
    //   this.$http.post('/user/auth_by_key', { key: token }).then((data) => {
    //     this.$store.dispatch("user/login", data.data);
    //
    //     this.$gener_boutique_id(data.data.user)
    //   }).catch(() => {
    //     this.logout()
    //   })
    // }
    //
    // this.$bo_boutique_id = parseInt(localStorage.getItem("bo_boutique_id"))
    
    let panier = localStorage.getItem("panier");
    if (!panier)
      localStorage.setItem('panier', '{}');
    
    this.$store.dispatch("user/init");
  }

}

</script>

<style src="./assets/css/normalize.css"></style>
<style>


.fade { transform: translateY(30px) !important }
.fade.in {
  transform: translateY(0) !important;
  transition: 0.7s cubic-bezier(0.5, 0, 0, 1)
}
.fade-1 { transition-delay: 0.2s!important; }


.el-collapse-item__wrap { background: none!important; }
.el-collapse-item__header { font-size: 14px!important; line-height: 22px!important; color: var(--black-main) !important; height: inherit!important; min-height: 50px!important;
  padding: 16px 0 16px 16px!important; background: none!important;
}
.el-collapse-item__content { color: var(--black-sub) !important; font-weight: 400!important; font-size: 14px!important; padding: 0 16px 16px 16px!important; }

@media only screen and (min-width: 800px) {
  .el-collapse-item__header { padding: 6px 0 6px 0px!important; font-size: 16px!important; }
  .el-collapse-item__content { padding-left: 0!important; }

}
.load .loading_animation {
  animation-timing-function: linear; animation-iteration-count: infinite;  animation-fill-mode: forwards;     animation-duration: 2s;     animation-name: loadingAnimation;
  background-image: linear-gradient(120deg, #E2E2E2 40%, #F6F6F6 48%, #E2E2E2 63%);
}
@keyframes loadingAnimation{from{background-position:-100vw 0px}to{background-position:100vw 0px}}

header { height: 64px; display: flex; align-items: center; justify-content: space-between; padding: 0 0 0 var(--padding); position: fixed; top: 0; right: 0; left: 0; background: #fff; z-index: 50;
  /*box-shadow: 0 2px 6px 0 rgba(0,0,0,.12), inset 0 -1px 0 0 #dadce0;*/ 
}
/*header { transition: all .001s cubic-bezier(0.4, 0.0, 0.2, 1); }*/
header svg { position: relative; top: 1px; fill: rgb(79,79,79) }
header .left { display: flex; align-items: center; height: 100%; }
header .left .burger { height: 100%; display: flex; align-items: center; padding-right: 14px; cursor: pointer; }
.marque { font-family: "Prompt"; font-size: 5.5vw; cursor: pointer; font-weight: 600; }
.marque .click { color: #ff7600; font-weight: 700; }
header .right { display: flex; align-items: center; padding-right: 10px; height: 100%; }
header .right svg { fill: #333; }
.user_ico { position: relative; cursor: pointer; width: 50px; height: 50px; }
.me_connecter { font-size: 16px; padding: 0px 10px; height: calc(100% - 30px); border-radius: 2px; background: #eee;
  display: flex; align-items: center; justify-content: center; color: var(--boColor-gris1); cursor: pointer;
}
header .panier_txt { font-size: 14px; margin-right: 14px; cursor: pointer; display: none; }
header .panier_ico {  background: var(--color); color: #fff; width: 38px; height: 100px; align-items: flex-end; justify-content: center; position: relative; top: -25px; 
  border-radius: 24px; padding-bottom: 11px; font-family: "Prompt"; font-weight: 500; cursor: pointer; display: none;}

header[data-headerShow] { top: 0 }

.bar_top { height: 5px; background: url(./assets/img/bar.jpg); position: absolute; bottom: -5px; left: 0; right: 0; z-index: 51; }

.main { padding-top: 69px; }


.tag { padding: 4px 6px; border-radius: 4px; background: #d6ecff; color: #3d4eac; display: inline-block; font-size: 12px }
.tag svg { fill: #3d4eac }
.tag.enstock { background: var(--gris); display: inline-flex; align-items: center; color: var(--black-main) }
.tag.enstock svg { margin-right: 6px; fill: var(--vert-button) }
.tag.alertstock { background: #f5f5f5; display: inline-flex; align-items: center; color: var(--boRed); font-size: 14px; }
.tag.alertstock svg { margin-right: 6px; fill: var(--boRed) }

.bo .bar, .bo_config .bar { background: #fff; padding: 0 16px; width: 100%; height: 36px; display: flex; align-items: center; justify-content: center; font-size: 16px; line-height: 16px;
  cursor: pointer; position: relative; z-index: 3}
.bo .bar i, .bo_config .bar i { padding-right: 6px; font-size: 18px; position: relative; top: -2px }
.bo .bar.red, .bo_config .bar.red { background: var(--boRed); color: #fff }


.local { font-size: 20px; padding: 0px 10px; display: flex; align-items: center; justify-content: center; color: var(--boColor-gris1); cursor: pointer; }
.local i { margin-right: 6px; font-size: 22px }

/*.user_ico { position: relative; cursor: pointer; width: 50px; height: 50px; background-size: 46%; background-position: center; background-repeat: no-repeat; background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' height='19' width='19'%3E%3Cpath d='M14 6a4 4 0 10-8 0 4 4 0 008 0zm2 0A6 6 0 114 6a6 6 0 0112 0zm-6 9c-3.068 0-5.67 1.223-7.035 3h14.07c-1.365-1.777-3.967-3-7.035-3zm10 5H0c.553-4.006 4.819-7 10-7s9.447 2.994 10 7z' fill='%23444'/%3E%3C/svg%3E")}*/
/*.panier_ico { position: relative; cursor: pointer; width: 35.83px; height: 35.83px; position: relative; top: -1px; padding-top: 2px; margin-left: 4px; background: #ede7f6; border-radius: 100% }*/
/*.panier_ico { position: relative; cursor: pointer; width: 50px; height: 50px; background-size: 50%; background-position: center; background-repeat: no-repeat; background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' height='19' width='19'%3E%3Cpath d='M4.083 5a6.002 6.002 0 0111.834 0H20v14.986L0 20V5h4.083zM14 5c0-1.001-1.06-3.274-4-3.274S6 4.006 6 5c0 0 8 .012 8 0z' fill='%23444'/%3E%3C/svg%3E")}*/
/*.panier_ico .indicator { position: absolute; top: -8%; right: -8%; background: #ff5f5f; border-radius: 100%; width: 12px; height: 12px; }*/
/*.panier_ico .indicator_nb { position: absolute; top: 44%; left: 54%; transform: translate(-50%, -50%); font-size: 8px;*/
/*  color: #fff*/
/*}*/


.opaque { position: absolute; background: rgba(10,10,10,.5); position: fixed; top: 0; right: 0; left: 0; bottom: 0; z-index: 140; }

.menu_active .nav { left: 0; }
.nav { position: fixed; top: 0; left: -400px; bottom: 0; width: 300px; background: #fff; z-index: 5500; padding: 32px 0; box-shadow: 5px 0 100px rgba(100,100,100,.5); font-size: 16px;
  transition: all .3s ease-in-out;
}
.nav .button { background: var(--boColor-gris1); color: #fff; height: 50px; display: flex; align-items: center; justify-content: center; margin: 0 32px 30px 32px; cursor: pointer; }
.nav .link { height: 42px; display: flex; align-items: center; border: none; color: var(--boColor-gris1); padding: 0 32px; cursor: pointer; display: flex; align-items: center }
.nav .link i { margin-right: 12px; font-size: 18px; color: #111 }
.nav .link.bold { font-size: 18px; height: 45px; }
.nav .link.bold i { font-size: 22px }
.nav .link.deco { color: var(--boColor-gris2); margin-top: 6px; }

.nav .title { font-size: 18px; padding: 12px 32px 10px }

.nav .user_pic { display: flex; align-items: center; margin-bottom: 24px; padding: 0 32px; cursor: pointer }
.nav .user_pic .pic { margin: 0!important; flex: 0 0 auto }
.nav .user_pic .right { padding-left: 16px; font-size: 18px; font-weight: bold; text-transform: capitalize; display: flex; flex-direction: column }
.nav .user_pic .right .email { font-weight: 500; font-size: 12px; text-transform: none; padding-top: 2px; }


.form_edit .el-form-item__label { float: none!important; }

.v-tour .v-step { background: #cc3636 !important; border-radius: 0.5rem!important; padding: 1.5rem!important; filter: drop-shadow(0 0 7px rgba(0,0,0,.5)) !important }

.v-tour .v-step[x-placement^=top] .v-step__arrow { border-top-color: #cc3636 !important }
.v-tour .v-step[x-placement^=bottom] .v-step__arrow { border-bottom-color: #cc3636 !important }
.v-tour .v-step[x-placement^=left] .v-step__arrow { border-left-color: #cc3636 !important }
.v-tour .v-step[x-placement^=right] .v-step__arrow { border-right-color: #cc3636 !important }

.v-tour .v-step .v-step__content { font-size: 14px!important; line-height: 20px!important; font-weight: 400!important; }
.v-tour .v-step__buttons { display: flex; }
.v-tour .v-step__buttons .v-step__button { margin: 0 0.2rem!important; border-radius: 6px!important; border-color: rgba(255,255,255,.5) }
.v-tour .v-step__buttons .v-step__button.v-step__button-next { border-color: rgba(255,255,255,1) }
.v-tour .v-step__buttons .v-step__button.v-step__button-stop { border-color: rgba(255,255,255,1) }


.title_a { height: 60px; display: flex; align-items: center; text-transform: uppercase; letter-spacing: 2px; font-weight: 200; font-size: 20px; }


hr { border: none; height: 1px; background: #ddd; margin: 12px 0 }

.force_right { display: flex; justify-content: flex-end; }
.force_bottom { position: absolute; left: 0; bottom: 0; right: 0; background: #fff; padding: 16px; box-shadow: 0 -4px 5px 0 rgba(0,0,0,.15); z-index: 5 }


.filters { display: flex; align-items: center; overflow-x: auto; }
.filters .filter { height: 32px; padding: 0 12px; background: #eee; border-radius: 50px; display: flex; align-items: center; justify-content: center; font-size: 14px;
 font-weight: 500; margin-right: 8px; flex: 0 0 auto; cursor: pointer; }
.filters .filter.active { background: #ff7600; color: #fff }
.filters .filter.active svg { fill: #fff; }
.filters .el-dropdown {flex: 0 0 auto }

.filters.v2 .filter:last-child { padding-right:  24px; }
.filters.v2 .filter { background: none; font-size: 16px; font-weight: 600; }
.filters.v2 .filter.active { color: var(--orange); position: relative; }
.filters.v2 .filter.active:after { content: ''; position: absolute; bottom: -2px; left: 50%; transform: translateX(-50%); width: 5px; height: 5px; border-radius: 100%; background: var(--orange) }

/*.filters .filter:first-letter { text-transform: uppercase; }*/

.capitalize { text-transform: capitalize; }

.card_e { border: 1px solid #DCDFE6; border-radius: 4px; padding: 11px 15px; }
/*.__PrivateStripeElement { padding: 0 16px!important; border: 1px solid #f23f3f!important; }*/


.vue-tel-input { height: 40px; border-color: #DCDFE6!important; border-radius: 4px!important; transition: border-color .2s cubic-bezier(.645,.045,.355,1) !important ;}
.vue-tel-input:focus-within { box-shadow: none!important; border-color: #EA8B4A!important; }



.shadow { box-shadow: 0 4px 10px 0 rgba(0,0,0,.05); }
.el-notification { box-shadow: 1px 1px 4px 0 rgba(0,0,0,.05) !important; border-radius: 2px!important; }
.bo .page { padding: 16px 8px 120px 8px }
.bo .page.padding { padding: 16px }
.bo .page .title { padding: 0 16px }
/*.bo .page .title { font-size: 18px; font-weight: bold; color: var(--boColor-gris1); margin-bottom: 16px; padding: 0 16px }*/
.bo .page.padding .title { padding-left: 0; }



.bo button { background: #ff7600; color: #fff; height: 40px; display: flex; align-items: center; justify-content: center; padding: 0 8px; border-radius: 2px;
  width: 100%; margin-right: 6px;
}
.bo button:last-child { margin-right: 0; }

.bo button.black { background: #ddd; color: inherit; }
button.simple { background: var(--gris); border-radius: 2px; height: 40px; color: inherit; }



@media only screen and (min-width: 800px) {
  .bo .page { padding-left: 0; padding-right: 0; }
  .bo .page .title { padding: 0 }

  header .panier_txt { display: block }
  header .panier_ico { display: flex }
  /*.bo .page .title { padding-left: 0; height: 40px; align-items: center; display: flex; }*/
  /*.bo .page .title span { padding-left: 4px; }*/
}



.el-step {}
.el-step.is-horizontal .el-step__line { top: 15px!important; }
.el-input-number { width: 100% !important; }
.el-textarea .el-input__count { height: 30px; }
.el-select { width: 100%; }
.el-checkbox.is-bordered { height: 50px!important; padding-top: 16px!important; padding-left: 22px!important; width: 100%; text-transform: inherit; }

.upload_block .el-upload { border: 1px dashed #d9d9d9; border-radius: 6px; cursor: pointer; position: absolute; top: 0; right: 0; left: 0; bottom: 0; overflow: hidden; }
.upload_block .el-upload:hover { border-color: #409EFF; }
.upload_block-uploader-icon span { font-family: "Arial"; font-size: 12px; position: relative; top: 10px }
.upload_block-uploader-icon { flex-direction: column;
  font-size: 28px; display: flex!important; align-items: center; justify-content: center; color: #8c939d; width: 100%; height: 100%; line-height: 178px; text-align: center;
}
.upload_block .img { width: 100%; height: 100%; display: block; }



.no_scrollbar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  background: #26252b;
}
::-webkit-scrollbar-thumb {
  background: #aaa;
}
::-webkit-scrollbar-track {
  background: #091221;
  border: 0 none #fff;
  border-radius: 53px;
}
::-webkit-scrollbar-track {
  background: #26252b;
}

/* #### Generated By: http://www.cufonfonts.com #### */

h4 { height: 60px; display: flex; align-items: center }

b { font-weight: normal; font-weight: bold; }









.tableau { margin-top: 4px; }
.tableau .ligne { height: 55px; display: grid; grid-template-rows: 100%; grid-template-columns: 10% 15% 8% 27% 15% 10% 15%; border-bottom: 1px solid #e6e5eb; transition: all .08s ease-in-out }
.tableau .ligne.head {height: 45px; font-weight: 400; text-transform: uppercase; }
.tableau .ligne.head .e { font-size: 14px; position: relative; font-weight: 500; }
.tableau .ligne.head .e.c { line-height: 1 }
.tableau .ligne.head .e.c span { font-size: 10px }
.tableau .ligne .e { display: flex; align-items: center; justify-content: center; font-size: 0.96vw; font-weight: 500; margin: 0 4px; color: #7b7b7f; text-align: center; transition: all .08s ease-in-out }
.tableau .ligne .e:first-child { margin-left: 0; }
.tableau .ligne .e.c { flex-direction: column }
.tableau .ligne .e.c span { font-size: 12px; font-weight: 400; color: #bbb5b5; }
.tableau .ligne .e:last-child { border-right: 0; }

/*.tableau .ligne .e .order_arraw { position: absolute; right: 0; top: 0; }*/

.tableau.no_margin .ligne .e { margin: 0 }

.tableau .ligne.head .e.avant_red:before { content: ''; position: absolute; top: -6px; left: 0; right: 0; height: 8px; background: #fdf5f8;
  border-top: 4px solid #ea325d; border-radius: 3px 3px 0 0; }
.tableau .ligne .e.avant_red { background: #fdf5f8; }
.tableau .ligne.head .e.avant_violet:before { content: ''; position: absolute; top: -6px; left: 0; right: 0; height: 8px; background: #f8f6fe;
  border-top: 4px solid #5b4be6; border-radius: 3px 3px 0 0; }
.tableau .ligne .e.avant_violet { background: #f8f6fe; }
.tableau .ligne.head .e.avant_turq:before { content: ''; position: absolute; top: -6px; left: 0; right: 0; height: 8px; background: #f3fafe;
  border-top: 4px solid #5cc6db; border-radius: 3px 3px 0 0; }
.tableau .ligne .e.avant_turq { background: #f3fafe; }
.tableau .ligne.head .e.avant_yellow:before { content: ''; position: absolute; top: -6px; left: 0; right: 0; height: 8px; background: #fffbf6;
  border-top: 4px solid #f5b936; border-radius: 3px 3px 0 0; }
.tableau .ligne .e.avant_yellow { background: #fffaf8; }

.tableau .ligne.head .e.avant_blue:before { content: ''; position: absolute; top: -6px; left: 0; right: 0; height: 8px; background: #f4f7fe;
  border-top: 4px solid #2263f4; border-radius: 3px 3px 0 0; }
.tableau .ligne .e.avant_blue { background: #f4f7fe; }

.tableau .ligne.head .e.avant_green:before { content: ''; position: absolute; top: -6px; left: 0; right: 0; height: 8px; background: #f1f8e6;
  border-top: 4px solid #75b80d; border-radius: 3px 3px 0 0; }
.tableau .ligne .e.avant_green { background: #f1f8e6; }

.tableau .ligne .e.avant { background: #f6f7fa!important; }
.tableau .ligne .e.red { color: #e32e58; font-weight: 900; }
.tableau .ligne .e.green { color: #69af61; font-weight: 900; }

.tableau .ligne.end { background: #f9f9f9; border-top: 1px solid #e6e5eb; }
.tableau .ligne.end .e { font-weight: 700; }
.tableau .ligne:last-child { border-bottom: 0; }
.tableau .ligne:not(.head):hover { opacity: 1; transform: scale(1.0015); background: #fff; box-shadow: 0 0 30px rgba(100,100,100,.1); z-index: 1 }
.tableau .ligne:not(.head):hover .e:not(.game_name) { opacity: 1; background: #fff }

.tableau .ligne.border .e { border-right: 1px solid #e6eaf1; }

.tableau .ligne .e.borderRight { border-right: 2px solid #e6eaf1; }
.tableau .zoom_color { position: relative; border-bottom: 0; }
.tableau .zoom_color .e { position: relative; z-index: 1; font-weight: bold; } /* faeae9 */
.tableau .zoom_color:before { content: ''; position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; background:  #dde9ff; border-radius: 4px; overflow: hidden }
.tableau .zoom_color .e { background: #dde9ff!important; }
.tableau .zoom_color.orange .e { background: #faeae9!important; }

.tableau .zoom_color.orange:before { background:  #faeae9; }
.tableau .zoom_color.white:before { background: #fff; box-shadow: 0 0 30px rgba(100,100,100,.1) }
.tableau .zoom_color.white2:before { background: #fff; box-shadow: 0 0 30px rgba(100,100,100,.1); z-index: 1; }
.tableau .zoom_color.white2 .e { background: #fff!important; }
.tableau .e.left { justify-content: flex-start; padding-left: 24px; }
.tableau .e.no_border_bottom { position: relative; }

.tableau .e.no_border_bottom:before { content: ''; position: absolute; left: 0; right: 0; bottom: -1px; height: 2px; z-index: 1; background: #fff }
.tableau .no_border { border: none }
.tableau .e .el-progress { margin-right: 10px; }
.tableau .background1 { background: #fff!important; }
.tableau .ligne.background2 .e:not(.background1).no_border_bottom:before { background: #f7f9fc }
.tableau .ligne.background2  { background: #f7f9fc }
.tableau .ligne.background2 .e { font-weight: 500; }
.tableau .ligne.small { height: 48px; }

.tableau .ligne .e.not_important { opacity: .6; font-weight: 400; }

.tableau .ligne.disabled { background: #fff; }
.tableau .ligne.disabled .e{ opacity: .4; }

/*.tableau .e.select_indicator:hover { border: 3px solid #4086fd; position: relative; cursor: pointer; }*/
/*.tableau .e.select_indicator:before { content: 'notes'; font-family: "Material Icons"; font-size: 14px; color: #fff; display: flex; align-items: center; justify-content: center; border-radius: 100%;*/
/*  background: #4086fd; width: 20px; height: 20px; position: absolute; z-index: 2; right: -8px; bottom: -8px; opacity: 0; transition: all .02s ease-in-out  }*/
/*.tableau .e.select_indicator:hover:before { opacity: 1; }*/

.tableau.load .e { position: relative; }
.tableau.load .ligne:not(.head) .e:after { content: ''; position: absolute; top: 50%; left: 50%; width: 80%; height: 45%; transform: translate(-50%, -50%); background: #eee; }
/*.tableau.load .euro:after { display: none }*/






/*@font-face {*/
/*  font-family: 'Uber Move';*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  src: local('Uber Move Text Regular'), url('./assets/fonts/Uber/UberMoveTextRegular.woff') format('woff');*/
/*}*/


/*@font-face {*/
/*  font-family: 'Uber Move';*/
/*  font-style: normal;*/
/*  font-weight: 200;*/
/*  src: local('Uber Move Text Light'), url('./assets/fonts/Uber/UberMoveTextLight.woff') format('woff');*/
/*}*/


/*@font-face {*/
/*  font-family: 'Uber Move';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  src: local('Uber Move Text Medium'), url('./assets/fonts/Uber/UberMoveTextMedium.woff') format('woff');*/
/*}*/


/*@font-face {*/
/*  font-family: 'Uber Move';*/
/*  font-style: normal;*/
/*  font-weight: bold;*/
/*  src: local('Uber Move Text Bold'), url('./assets/fonts/Uber/UberMoveTextBold.woff') format('woff');*/
/*}*/


  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  /*@font-face { font-family: 'The Bold Font';*/
  /*  src: url('./assets/fonts/TheBoldFont.woff2') format('woff2'),*/
  /*      url('./assets/fonts/TheBoldFont.woff') format('woff');*/
  /*  font-weight: bold; font-style: normal; font-display: swap;*/
  /*}*/




/*@font-face{*/
/*  font-family:'Product Sans'; font-weight: normal;*/
/*  src: url(https://assets.skello.io/assets/ProductSans-Regular-7d37fb40e97e9dd722d36c1563c39d7cda4864ec2828bc916004a6ae47cd9568.woff) format("woff"),*/
/*  url(https://assets.skello.io/assets/ProductSans-Regular-202a8f0ceb9c59eaeb8411e011857cca6ed9dcdddd2f343c8947e0a08da259fd.woff2) format("woff2")*/
/*}*/
/*@font-face{*/
/*  font-family:'Product Sans'; font-weight: 900;*/
/*  src: url(https://assets.skello.io/assets/ProductSans-Black-131ebb65144799dbce19fc78166d36188bfdf5ebdafacbe7559b88858e9edc83.woff) format("woff"),*/
/*  url(https://assets.skello.io/assets/ProductSans-Black-4e28b5eb1d117125fdbfe507fe126d1f85d314aa8abfd98b6f6839cc0e4f17cb.woff2) format("woff2")*/
/*}*/
/*@font-face{*/
/*  font-family:'Product Sans'; font-weight: bold;*/
/*  src: url(https://assets.skello.io/assets/ProductSans-Bold-bd69d2572c8670cb937bf5f1dc111c67d213d1ea90eb3afeedd5a33261f27fb2.woff) format("woff"),*/
/*  url(https://assets.skello.io/assets/ProductSans-Bold-14bbfbc281cdeaeed039a6b2f67b169d41e724a268f74717dab81e130f96f5e9.woff2) format("woff2")*/
/*}*/
/*@font-face{*/
/*  font-family:'Product Sans'; font-weight: 600;*/
/*  src: url(https://assets.skello.io/assets/ProductSans-Medium-a9f7c764c98fcfaf3c6b00e0215f3adbc81c7c3e8262f8cec078a9463fab98d5.woff) format("woff"),*/
/*  url(https://assets.skello.io/assets/ProductSans-Medium-339ff3a8174b30d22826c0fdcfa4d0e27c9600b81dcc589206c93c7d1e0a2ee7.woff2) format("woff2")*/
/*}*/


    
  html {
    vertical-align: baseline;
  }
  body {
    font-family: Arial, serif; font-size: 1.5rem; font-weight: 500; color: var(--black-main); vertical-align: baseline; -webkit-text-size-adjust: 100%; -webkit-font-smoothing: antialiased; overflow-x: hidden;
  }
  
  :root { 
    --h_gradient: linear-gradient(83.84deg, #131313 -6.87%, #5010ce 26.54%, #9d00ff 58.58%);
    --padding: 16px;
    --color: #ff7600;
    --vert: #44ac06;
    --orange: #fb7f2a;
    
    --white-main: #f2f2f3;
    --white-sub: #8c8ea1;
    --black-list: #292e3c;
    
    --black-main: #4a4850;
    --black-sub: #9ea7b5;
    --label: #949ca9;
    --gris: #ecedf0;
    --input: #f6f6f6;
    --blanc-button: #fafafa;
    --vert-button: #3bad4c;
    --orange-button: #ff9938;
    --gris-button: #ced3da;
    --ico-button: #bac0ca;
    --ico-solo: #bbc1cb;
    
    --boColor-gris1: #444;
    --boColor-gris2: #7b7b7f;
    --boColor-gris3: #bbb5b5;
    --boRed: #f23f3f;
    --boVert: #75b80d;
    --boTurq: #19b8d2;
    --boBlue: #2263f4;
    --boOrange: #f5b936;
    --boRose: #ff9a9a;
    --boViolet: #5b4be6;
    
    --linear-orange: transparent linear-gradient(40deg, #f23f3f 0%, #fb7f2a 100%) 0% 0% no-repeat padding-box;
    --linear-vert: transparent linear-gradient(40deg, #74d839 0%, #44ac06 100%) 0% 0% no-repeat padding-box;
  }
  
@media only screen and (min-width: 1400px) {
  :root {
    --padding: 80px
  }
}

.infoLine { font-size: 15px;  font-weight: 500; display: inline-block; background: #e1e2ef; color: #6772e5; padding: 4px 8px; margin: 0 0 16px 0; border-radius: 0 }
.infoLine.red { background: #fde2dd; color: #a41c4e; }
.infoLine.green { background: #cbf4c9; color: #0e6245; }
.infoLine.gray { background: #e3e8ee; color: #4f566b; }
    
  h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0 } 
  /*h1, h2, h3, h4, h5, h6 { font-family: 'The Bold Font'; margin: 0; padding: 0 } */
  p { font-size: 18px; color: rgba(89, 89, 89, 1); line-height: 30px; word-break: keep-all; }
  
  .title_gradient { -webkit-background-clip: text; color: transparent; background-image: var(--h_gradient) }
  
  button { background: var(--vert); color: #fff; border: none; height: 50px; padding: 0 20px; border-radius: 1px; font-size: 18px; cursor: pointer; }
  button.disabled, button[data-disabled] { opacity: .2!important; background: #111!important; }
  button.black { opacity: 1; background: #444 }
  
  button.orange { background: var(--linear-orange) }
  button.rounded { border-radius: 50px }
  
  /*.anim { position: relative; }*/
  .anim, .animreverse { opacity: 0; }
  
  /*input { height: 50px; border: none; background: none; border: 1px solid #a130f7; outline: none }*/
  /*input:focus { background: #eee; }*/
  
  label { font-weight: 500; font-size: 16px; text-transform: uppercase; padding-top: 34px; padding-bottom: 10px; }
  .info_input { font-size: 12px; padding-top: 16px; padding-bottom: 24px; letter-spacing: 1.1px; line-height: 20px }
  label:nth-of-type(1) { padding-top: 0; }
  
  /*.info { font-size: 18px; padding-top: 16px; }*/

a { color: inherit; text-decoration: none; border-bottom: 1px solid var(--boColor-gris3); padding-bottom: 1px; }
* {  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); }
  .center { text-align: center }
  /*.form:after { content: ''; background: var(--h_gradient); height: 16px; position: absolute; left: 0; bottom: 0; right: 0; }*/

  .tap { cursor: pointer }
  .tap:active { animation: tap .2s; position: relative; z-index: 2 }
  @keyframes tap {
    0% { transform: scale(1) }
    50% { transform: scale(1.1) }
    100% { transform: scale(1) }
  }
  
  .digits { display: flex; }
  .digits .otp-input { width: 100%; margin-right: 16px; text-align: center; font-weight: 600; }
  .digits div:last-child { margin-right: 0; }


.route { font-size: 14px; display: flex; align-items: center; padding: 0px var(--padding); background: #fff; height: 48px; border-top: 1px solid #eee; border-bottom: 1px solid #eee; }
.route .elem:after { content: '>'; padding-left: 4px; padding-right: 4px;}
.route .elem:last-child:after { content: '' }
.route .elem:not(:last-child) { font-weight: 500; cursor: pointer; }


.info_line { font-size: 16px; padding: 14px var(--padding); min-height: 70px; display: flex; align-items: center; border-bottom: 1px solid var(--gris); }
.info_line .right { display: flex; flex-direction: column; width: 100% }
.info_line .right span { font-weight: 400; padding-top: 4px; font-size: 14px; color: var(--black-sub) }
.info_line .right span i { font-style: inherit }
.info_line.padding { margin-left: 48px; padding-left: 20px; border-left: 1px solid #eee; }
.info_line .el-select { margin-top: 8px; }
.info_line .el-input { margin-top: 8px; }

.select_type { }
.select_type .title_a { margin: 0!important; }
.select_type .elem { display: flex; align-items: center; padding: 6px 20px; cursor: pointer; background: #fff;  }
.select_type .elem .ico { width: 60px!important; max-width: 60px; margin: 0!important; }
.select_type .elem .right { width: calc(100% - 110px); padding-left: 16px; }
.select_type .elem .title { font-size: 16px }
.select_type .elem .title span { color: var(--vert-button); padding-left: 4px; }
.select_type .i { background: #f9f9f9 }
.select_type .elem .desc { font-weight: 200; font-size: 14px; color: var(--black-sub) }
.select_type .elem .select { width: 20px; height: 20px; border-radius: 100%; border: 2px solid #333; margin: 0 0 0 30px;  }
.select_type .elem.active .select { background: #333 }
.select_type .elem.active { background: #eee }

.fw { width: 100%; }

.el-scrollbar__wrap { overflow-x: hidden!important; }

.el-form--label-top .el-form-item__label { padding: 0!important; }

.el-notification__title { padding-top: 3px; }
.el-notification__content p { font-size: 14px; line-height: 16px }

.el-switch.color:not(.is-disabled) .el-switch__core { background: var(--boRed); border-color: var(--boRed) }
/*.el-switch.color:not(.is-disabled):not(.is-checked).red_blink .el-switch__core { animation: red_blink .8s infinite alternate }*/

@keyframes red_blink {
  60% { background: var(--boRed); border-color: var(--boRed) }
  to{ background: var(--gris-button); border-color: var(--gris-button) }
}

.el-switch.is-checked.color .el-switch__core { background: var(--boVert); border-color: var(--boVert) }


.sep { height: 4px; }


  .flare_effect:not(.disabled) {
    position: relative;
    overflow: hidden;
    transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .flare_effect:not(.disabled):after,
  .flare_effect:not(.disabled):before {
    background: linear-gradient(to top right, transparent, #fff);
    content: "";
    height: 150px;
    left: -175px;
    opacity: 0.4;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    width: 100px;
  }
  .flare_effect:not(.disabled):before {
    animation: left 10s infinite;
  }
  .flare_effect:not(.disabled).delay:before {
    animation-delay: .2s;
  }
  .flare_effect:not(.disabled):hover {
    transform: scale(1.1);
  }
  .flare_effect:not(.disabled):hover:after {
    left: 120%;
    transition: left 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  }


.slide-fade-enter-active { transition: all 0.15s cubic-bezier(0.5, 0, 0, 1); }
.slide-fade-enter{ opacity: 0; }

/*.slide-fadeMain-enter-leave { transition: all 0.2s cubic-bezier(0.5, 0, 0, 1); }*/
.slide-fadeMain-enter-active { transition: all 0s linear; }
.slide-fadeMain-enter{ opacity: 0; }
/*.slide-fadeMain-leave{ opacity: 0; }*/
/*.slide-fade-enter-active { transition: all .075s linear; }*/
/*.slide-fade-enter{ transform: scale(.99); opacity: 0; }*/

.slide2-fade-enter-active { transition: all .2s linear; }
.slide2-fade-enter{ transform: scale(.99); opacity: 0; }

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

/*.slide-fade-leave-active { opacity: 0; }*/

.slide-fade-leave
  /* .slide-fade-leave-active below version 2.1.8 */ {
  /*transform: scale(1.01);*/
  /*opacity: 1;*/

}



#app[data-nav_hide_mobile] header { opacity: 0; }
#app[data-nav_hide_mobile] .bar_top { opacity: 0; }

#app[data-loader]:not([data-load]):after { content: ''; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 5000; background: #fff }

/*#app[data-load] header { opacity: 1; }*/
/*#app[data-load] .bar_top { opacity: 1; }*/

@media only screen and (min-width: 800px) {
  #app[data-nav_hide_mobile] header { opacity: 1; }
  #app[data-nav_hide_mobile] .bar_top { opacity: 1; }
  
  header { padding-right: var(--padding); }

  header .left .marque { font-size: 26px; }
  header .right .me_connecter { font-size: 18px; padding: 0; background: none; margin-left: 24px; }

  #app[data-loader]:after { content: none; display: none }
    
  }

</style>
