<template>
  <div class="home" :class="{ no_locate: !$ville }" :data-pop_resto="commercants_close_etat && !$store.state.user.user.boutique">
    
<!--    <div class="commercants" v-if="load && commercants_close_etat && !$store.state.user.user.boutique">-->
<!--      <router-link tag="div" :to="{ name: 'votreresto' }" class="title"><b>Restaurateur ?</b> Créer votre restaurant gratuitement en quelques clics <i class="el-icon-thumb"></i></router-link>-->
<!--      <i @click="commercants_close" class="el-icon-close close"></i>>-->
<!--    </div>-->
    
<!--    <div class="annonce">-->
<!--      <div class="bg"></div>-->
<!--      -->
<!--      <div class="title">Vos restos en un click</div>-->
<!--      -->
<!--      <div class="button_bloc" v-if="!$ville">-->

<!--        <button @click="search_etat = true">-->
<!--          <svg width="24px" height="24px" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="presentation" aria-hidden="true" focusable="false"><path data-v-6c650446="" d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z" fill="#fff"></path></svg>-->
<!--          Trouver mon resto</button>-->
<!--      </div>-->
<!--    </div>-->


    <bandeau_resto @close="commercants_close" v-if="load && commercants_close_etat && !$store.state.user.user.boutique && $ville"></bandeau_resto>
    <div class="annonce" v-else-if="load && $ville">
      <div class="bg"></div>
    </div>

    <intro v-if="!$ville && intro_mobile && mobile" @close="intro_mobile = false"></intro>
    <div class="introSelectCity" v-else-if="load && !$ville">

      <div class="annonce" v-if="load && (!commercants_close_etat || $store.state.user.user.boutique)">
<!--        <div class="bg"></div>-->
      </div>
  
  
      <bandeau_resto @close="commercants_close" v-if="load && commercants_close_etat && !$store.state.user.user.boutique"></bandeau_resto>
      
      
      <div class="intro">
        <div class="l">
          
        <div class="t">Une petite faim ?
          <span>Trouvez votre restaurant</span>
        </div>
        <search_city :fullscreen="false" label="Rechercher ma ville" @result="search_etat = false" @close="search_etat = false" class="search_city"></search_city>
  
  <!--              <img src="../assets/img/illu/intro/perso.svg" alt="" class="perso">-->
        </div>
        <div class="right">
          
  <!--      <img src="../assets/img/illu/intro/sac.svg" alt="" class="perso">-->
          <img src="../assets/img/illu/intro/bike1.svg" alt="" class="place">
        </div>
      </div>



      
    </div>
    
    <transition name="slide-fade">
      <search_city v-if="search_etat === true" :fullscreen="true" label="Rechercher ma ville" @result="search_etat = false" @close="search_etat = false" class="search_city"></search_city>
    </transition>
    
    <div class="boutiques" v-if="$ville">
      <div class="title_big animreverse">Vos restos préferés à <b>{{ $ville.name }}</b></div>
      
      
      <div class="filters animreverse no_scrollbar">

        <el-dropdown @command="filter_activity_set" trigger="click">
          <div class="filter" :class="{ active: filter_activity !== 'all' }">
            <svg width="20px" height="20px" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-label="Filtres" role="img" focusable="false"><circle cx="8" cy="8" r="3" stroke="#000000" stroke-width="2"></circle><path d="M11 8H20" stroke="#000000" stroke-width="2"></path><circle cx="16" cy="16" r="3" transform="rotate(180 16 16)" stroke="#000000" stroke-width="2"></circle><path d="M13 16L4 16" stroke="#000000" stroke-width="2"></path></svg>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="all">Toutes les catégories</el-dropdown-item>
            <el-dropdown-item :command="n" v-for="n in $activity" :key="n">{{ n }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        
        
        <div class="filter" @click="search_etat = true">Changer de ville</div>
<!--        <el-dropdown @command="filter_round" trigger="click">-->
<!--          <div class="filter" v-if="$ville.round > 0">{{ $ville.round }}km autour de {{ $ville.name }}</div>-->
<!--          <div class="filter" v-else>Uniquement à {{ $ville.name }}</div>-->
<!--          <el-dropdown-menu slot="dropdown">-->
<!--            <el-dropdown-item command="0">Uniquement à {{ $ville.name }}</el-dropdown-item>-->
<!--            <el-dropdown-item command="10">10km autour de {{ $ville.name }}</el-dropdown-item>-->
<!--            <el-dropdown-item command="30">30km autour de {{ $ville.name }}</el-dropdown-item>-->
<!--            <el-dropdown-item command="50">50km autour de {{ $ville.name }}</el-dropdown-item>-->
<!--            <el-dropdown-item command="100">100km autour de {{ $ville.name }}</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
      </div>
      
<!--      <search_city v-else label="Rechercher votre ville" @result="search_etat = false" class="search_city"></search_city>-->

      <div class="empty anim" v-if="!boutiques.length && load === true">
        <img src="../assets/img/illu/pasencorela.svg" alt="">
        <div class="right">
          <div class="title">Nous ne sommes pas encore là</div>
<!--          <p>Pour l'instant ! </p>-->
          <p>Pour l'instant ! Nous pouvons vous envoyer un e-mail dès que nous serons présents dans cette zone.</p>
          <button v-if="!city_subscribe_email_etat" @click="city_subscribe_email_etat = true" class="orange rounded">Envoyez moi un e-mail</button>
          <form action="" class="city_subscribe" @submit.prevent="city_subscribe">
            <el-input name="email" :disabled="city_subscribe_email_submit" v-if="city_subscribe_email_etat" type="email" v-model="city_subscribe_email" placeholder="Votre adresse email" required></el-input>
            <button v-if="city_subscribe_email_etat" class="orange rounded">Tenez moi informé !</button>
          </form>
        </div>
      </div>
      
      <div class="boutiques_list">
        <boutique_vignette v-for="(n, k) in boutiques_show" :key="k" :data="n" class="vignette tap anim"></boutique_vignette>
      </div>
      
      <div v-if="auto && auto.length">
        <div class="title_big min">Ils n'acceptent pas encore les commandes, <span class="small">Mais nous sommes sûr qu'ils peuvent vous intéresser !</span></div>
      
        <div class="boutiques_list auto">
          <a target="_blank" :href="'http://www.google.com/search?q=' + n.name" v-for="(n, k) in auto" :key="k" >
            <boutique_vignette :data="n" :auto="true" class="vignette animAuto"></boutique_vignette>

          </a>
        </div>
        
      </div>
    </div>


    <faq_client class="anim"></faq_client>
    
    <footer_b class="anim"></footer_b>
    
  </div>
</template>

<script>
// @ is an alias to /src

import boutique_vignette from "@/components/boutique/boutique_vignette";
import search_city from "@/components/global/search_city"
import faq_client from "@/components/global/faq_client"
import bandeau_resto from "@/components/global/bandeau_resto"
import intro from "@/components/home/intro"
import anime from 'animejs'
export default {
  data() {
    return {
      boutiques: [],
      boutiques_show: [],
      search_etat: false,
      
      intro_mobile: true,
      
      auto: null,

      city_subscribe_email: null,
      city_subscribe_email_etat: false,
      city_subscribe_email_submit: false,
      
      animation_duration: 300,
      animation_boutique_duration: 400,
      
      filter_activity: 'all',
      
      load: false,
      
      mobile: false,

      commercants_close_etat: true,
    }
  },
  components: {
    boutique_vignette,
    search_city,
    faq_client,
    intro,
    bandeau_resto
  },
  watch: {
    $route() {
      this.check_seo()
    },
    $ville() {

      if(this.$ville && this.$ville.name && this.$ville.cp && this.$ville.code) {
        if (this.$route.name === 'ville')
          this.$router.replace({ path: '/' })
        
        if (this.$route.name === 'Home') {
          this.$router.replace({name: 'ville', params: {code: this.$ville.code, name: this.$ville.name, cp: this.$ville.cp}})
        }
      
        this.load = false
        this.auto = null
        this.$http.get('/geo/city/' + this.$ville.code + '/6').then((response) => {
          this.boutiques = response.data
          this.boutiques_show = response.data
          this.animation_boutiques_opacity()
          this.animation()
          this.load = true
        }).catch(e => console.log(e))
          this.get_autoinsert()
        }
    }
  },
  created() {
    
    if(window.innerWidth < 800) 
      this.mobile = true
      
    let self = this
    
    if(window.innerWidth < 800) {
      this.animation_duration = 0
      this.animation_boutique_duration = 0
      this.$nextTick(() => {
        var elems = document.querySelectorAll('.anim, .animreverse');
        var index = 0, length = elems.length;
        for ( ; index < length; index++) {
          elems[index].style.opacity = 1;
        }
      })
      
    }
    
    this.load = false
    
    if(localStorage.getItem('commercants_close'))
      this.commercants_close_etat = false

  
    let city = null
    if(this.$ville) 
      city = this.$ville
    
    if(this.$route.name === 'ville') {
      this.$ville = {
        code: this.$route.params.code, 
        name: this.$route.params.name,
        cp: this.$route.params.cp,
        round: 0
      }
    }
    
    
    if(this.$ville) {
      this.check_seo()
    }
    if(this.$ville && this.$route.name === 'ville') {

      
      setTimeout(() => {
        if(city && self.load === false)
          self.$loader = true
      }, 400)
    //   this.$loader = true
      
      
      // if(this.$ville.name && !this.$ville.code) {
      //   localStorage.removeItem('ville_tab');
      //   location.reload()
      // }
      
      this.$http.get('/geo/city/' + this.$ville.code + '/6').then((response) => {
        this.get_autoinsert()
        this.$loader = false
        this.boutiques = response.data
        this.boutiques_show = response.data
        
        this.animation()
        this.animation_boutiques()
        
        this.load = true
  
        this.$root.$emit('triggerScroll')
      }).catch(e => console.log(e))
      
    }
    else {
      this.animation_boutiques()
      this.load = true
    }
  },
  methods: {
    check_seo() {
      if(this.$ville) {
        
        document.title = 'Restaurants sur ' + this.$ville.name.toUpperCase() + ' | Commandez vos repas en quelques clics | Livraison à domicile | MonResto.click'
        
        // if (this.$route.name === 'Home') 
        //   this.$router.replace({name: 'ville', params: {code: this.$ville.code, name: this.$ville.name, cp: this.$ville.cp}})

      }
    },
    city_subscribe() {
      if(this.city_subscribe_email_submit === false) {
        this.city_subscribe_email_submit = true
        this.$http.post('/marketing/city_subscribe', {
          code: this.$ville.code,
          email: this.city_subscribe_email
        }).then(() => {
          this.$notify({
            title: 'Promis, vous serez le premier informé !',
            type: 'success'
          });
        }).catch(() => {
          this.$notify({
            title: 'Vous êtes déjà inscrit, mais ok !',
            type: 'success'
          });
        })
      }
    },
    animation() {
      if(this.load === false) {
        anime({
          targets: '.animreverse', translateX: ['-30px', 0], opacity: 1, easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', duration: this.animation_duration, delay: anime.stagger(30)
        })
      }
    },
    animation_boutiques_opacity() {
      if(this.load === false) {
        setTimeout(() => {
          anime({
            targets: '.anim', opacity: 1, easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', duration: this.animation_duration, delay: anime.stagger(30)
          })
        })
      }
    },
    animation_boutiques() {
      if(this.load === false) {
        setTimeout(() => {
          anime({
            targets: '.anim', translateY: ['30px', 0], opacity: 1, easing: 'cubicBezier(0.0, 0.0, 0.2, 1)', duration: this.animation_boutique_duration, delay: anime.stagger(30)
          })
        }, 140)
      }
    },
    filter_activity_set(command) {
      this.filter_activity = command
      if(command !== 'all') {
        this.boutiques_show = this.boutiques.filter(function(b) {
          return b.activity === command
        });
      }
      else this.boutiques_show = this.boutiques
      this.animation_boutiques_opacity()
      this.$nextTick(() => {
        var elems = document.querySelectorAll('.anim, .animreverse');
        var index = 0, length = elems.length;
        for ( ; index < length; index++) {
          elems[index].style.opacity = 1;
        }
      })
      console.log(command)
    },
    filter_round(command) {
      let v = this.$ville
      v.round = parseInt(command)
      this.$ville = v
      console.log(v)
    },
    get_autoinsert() {
      let v = this.$ville.name
      // let v = this.$ville.name.replaceAll(' ', '+')
      // v = v.replaceAll('-', '+')
      //
      // v = v.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      console.log(v)
      
      // if (process.env.NODE_ENV === "production") {
        this.$http.get('/geo/city/' + v + '/auto').then((response) => {
          this.auto = response.data
          setTimeout(() => {
            anime({
              targets: '.animAuto', opacity: 1, easing: 'easeOutQuint', duration: 600, delay: anime.stagger(30)
            })
          })
        }).catch(e => console.log(e))
      // }
    },
    commercants_close() {
      this.commercants_close_etat = false
      localStorage.setItem('commercants_close', true)
    }
  }
}
</script>


<style scoped>


  .introSelectCity { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 150; display: flex; flex-direction: column-reverse; justify-content: space-between;
    overflow-y: auto; min-height: 600px;
  }
  
  
  .intro { padding: 24px var(--padding); min-height: 300px; }
  [data-pop_resto] .intro {}
  .intro .t { font-weight: 600; margin-bottom: 16px; font-size: 28px; display: flex; flex-direction: column }
  .intro .t span { font-weight: 500; font-size: 17px; color: var(--black-sub) }
  .intro .t span::first-letter { text-transform: capitalize; }
  .intro .perso { position: absolute; width: 60%; right: 0; bottom: 6%; z-index: 5 }
  .intro .right { display: none }
  .introSelectCity .annonce { opacity: 0; }
  
  

  .city_subscribe { display: flex; flex-direction: column; align-items: center; }
  .city_subscribe .el-input { width: 300px; margin-bottom: 16px; }

  .commercants { background: #d43838; color: #fff; font-size: 18px; padding: 16px var(--padding); display: flex; align-items: center; justify-content: space-between;
    position: relative; }
  .commercants .title { margin-bottom: 0px; display: flex; justify-content: center; padding-right: 32px; flex-direction: column; font-size: 14px; line-height: 18px; color: #eee; cursor: pointer; }
  .commercants .title b { font-size: 20px; line-height: 20px; padding-bottom: 4px; text-transform: uppercase; color: #fff }
  .commercants .button_add { width: 60px; min-width: 60px; height: 60px; border-radius: 100%; background: #e26a6a; display: flex; align-items: center; justify-content: center }
  .commercants .button_add svg { fill: #fff }
  .commercants .title i { display: none }
  .commercants .close{ position: absolute; top: 12px; right: 10px; }

  .boutiques { padding: 0; padding: 0  var(--padding); min-height: 400px; }
  .boutiques .title_big { display: flex; flex-direction: column; margin-top: 24px; margin-bottom: 16px; justify-content: center; font-weight: 400; }
  .boutiques .title_big .small { font-size: 14px; color: var(--black-sub) }
  .boutiques .vignette { margin-bottom: 20px; cursor: pointer;  }

  .filters { margin-bottom: 16px; height: 40px; }

  
  /*.annonce { height: calc((var(--vh, 1vh) * 100) - 165px); overflow: hidden; position: relative;*/
  .annonce { height: 90px; overflow: hidden; position: relative;
    display: flex; align-items: center; justify-content: center; font-size: 14vw; font-weight: 900; flex-direction: column; text-align: center;
    
  }
  .annonce .bg { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: url(../assets/img/bg/2.jpg) 0 91.2% #ffeee5; background-size: cover;
    /*animation: background_home reverse .4s ease-in; animation-fill-mode: both;*/ z-index: -1 }
  
  /*.home:not(.no_locate) .annonce { height: 90px; }*/
  /*.home .annonce { height: 90px; }*/
  .home .annonce .bg { background-position: 0 38% }
  /*.home:not(.no_locate) .annonce .title { display: block }*/
  
  @keyframes background_home {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  .annonce span { font-size: 18px; color: #44ac06; font-weight: 600; text-transform: uppercase; margin-bottom: 28px; }
  .annonce .button_bloc { position: absolute; left: 0; bottom: 0; right: 0; display: flex; padding: 16px }
  .annonce button { background: #ca3333; color: #fff; font-weight: 500; font-size: 20px; width: 100%; display: flex; align-items: center; justify-content: center;  
    box-shadow: 3px 3px 0 1px #a92929;
  }
  .annonce button svg { fill: #fff; margin-right: 6px; }
  .annonce .title { font-size: 38px; line-height: 40px; padding: 0 32px; color: #fff; font-weight: 900; text-shadow: 3px 3px 16px rgb(169 129 144); display: none }
  
  .search_city { margin-right: 16px; }
  
  .empty { display: flex; flex-direction: column; align-items: center; text-align: center; padding: 32px 0; font-family: "Product Sans"; margin-bottom: 36px; }
  .empty .title { font-size: 18px; font-weight: bold; padding-top: 16px; }
  .empty p { font-size: 16px; line-height: 22px; padding: 10px 16px; }
  .empty img { width: 80%; }

  .boutiques_list:not(.auto) { margin-bottom: 20px; padding-bottom: 20px; border-bottom: 1px solid var(--gris); }

  .boutiques .title_big.min { margin-bottom: 20px; margin-top: 20px; }

  @media only screen and (min-width: 800px) {
    
    .introSelectCity { position: static;  }
    
    .intro { display: flex; margin-top: 0; padding-top: 0; position: relative; min-height: 0; height: calc(100vh - 60px);
      justify-content: center; align-items: center; min-height: 400px; background: #fff; max-height: 920px;
    }
    /*.intro:before { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: url(../assets/img/test/4.png) -750px 55% no-repeat; background-size: 101%; opacity: 1; }*/
    .intro:before { content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: url(../assets/img/test/5.jpg) center no-repeat; background-size: 131%; opacity: .16; 
      animation: backgroundScale 1.5s;
    }
    [data-pop_resto] .intro { height: calc(100vh - 349px); }
    @keyframes backgroundScale {
      from { background-size: 120% }
      to { background-size: 131% }
    }
    
    
    .intro .t { margin-right: 36px; font-size: 42px; width: 600px; margin-bottom: 26px; margin-right: 0; text-align: center; position: relative; }
    .intro .t span { font-size: 26px }
    .intro .search_city { width: 600px; max-width: 600px; margin-right: 0; padding: 16px; box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%); border-radius: 16px;
      backdrop-filter: blur(100px); background: rgba(255,255,255,1);
    }
    /*.intro .search_city:hover { box-shadow: none }*/
    .intro .left {  }
    .intro .right { width: calc(100% - 600px - (var(--padding) * 2)); position: absolute; height: 100%; right: 0; background: var(--input); display: flex; align-items: flex-end;
      justify-content: center; display: none }
    .intro .right img { height: 86%; }
    
    /*.home .annonce { display: flex!important; height: calc((var(--vh, 1vh) * 100) - 115px) }*/
    .home:not(.no_locate) .annonce { height: 32.2vh;  }
    .home:not(.no_locate) .annonce .bg { background-position: center 10%; background-image: url(../assets/img/bg/2.jpg) }
    .home:not(.no_locate) .annonce .title { position: relative; z-index: 1; font-size: 68px; line-height: 70px; text-shadow: 3px 3px 16px rgb(44 25 35), 0 0 100px rgba(0,0,0,0.8); width: 600px;
      display: none;
    }

    .commercants { padding: 10px var(--padding); justify-content: center }
    .commercants .title { flex-direction: row; align-items: center; margin-bottom: 0; padding: 4px 0; }
    .commercants .title b { padding-bottom: 0; margin-right: 16px; }
    .commercants .title i { margin-left: 10px; font-size: 20px; display: block }
    .commercants .button_add { width: 40px; height: 40px; min-width: 40px; display: none }
    .commercants .close { top: 50%; transform: translateY(-50%) }

    .boutiques { padding: 0 var(--padding) }
    
    /*.boutiques { padding: 0 80px }*/
    .boutiques_list { display: flex; flex-wrap: wrap; margin-top: 30px; min-height: 380px; }
    .boutiques_list .vignette { width: calc((100% / 3) - 10px); margin-right: 14px; margin-bottom: 30px; }
    .boutiques_list .vignette:nth-child(3n + 3) { margin-right: 0; }


    .boutiques_list.auto a { width: calc((100% / 4) - 18px); margin-right: 24px; margin-bottom: 0px; border: none!important; }
    .boutiques_list.auto a .vignette { width: 100%; }
    .boutiques_list.auto a:nth-child(4n + 4) { margin-right: 0; }
    
    
    
    /*.annonce { height: calc((var(--vh, 1vh) * 100) - 122px); font-size: 4.5vw; }*/

    .search_city { max-width: 600px }
    
    .annonce { background: #000 }
    .annonce .button_bloc { bottom: 50%; transform: translateY(50%); width: 400px; margin: auto; background: rgba(0,0,0,.1); box-shadow: 0 0 1000px 4px #fff }
    .annonce .bg { opacity: .8; z-index: 0 }
    .annonce .button_bloc button { width: 350px; margin: auto; }

    /*.annonce .bg { opacity: .2; z-index: 0 }*/
    /*.annonce .button_bloc { bottom: 50%; left: 50%; transform: translate(-50%, 50%) }*/
    /*.annonce .button_bloc button { width: 300px; margin: auto; position: relative; z-index: 2 }*/
    /**/
    .annonce svg.forme_top { position: absolute; top: -10%; left: -0%; right: -0%; fill: #111; display: none }
    .annonce svg.forme_bottom { position: absolute; bottom: 0%; left: -0%; right: -0%; fill: #111; display: none }
    
    .empty { padding: 0 }
    .empty img { width: 30%; }
    .empty .title { font-size: 24px; padding-top: 32px; }
    .empty p { max-width: 600px; }

    .boutiques .title_big { font-size: 28px; height: 120px; margin-top: 5px; margin-bottom: 1px; }
    .boutiques .title_big .small { font-size: 18px; font-weight: 400; }
    .boutiques .title_big.min { height: 50px; font-weight: 500; margin-top: 30px; }
    .boutiques .title_big b { font-weight: 900; }
    
    .filters { height: 35px; }
    .filters .filter { font-size: 16px; font-weight: 600; height: 35px; padding: 0 16px; border-radius: 50px }
    /*.vignette:hover {  box-shadow: 5px 8px 12px rgba(141,149,158,0.11); }*/
  }




</style>