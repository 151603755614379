<template>
  <div class="search_city" :class="[ { fullscreen: fullscreen } ]">
    <div id="search_opaque"></div>
    
    <div class="input_block">
      
    <div @click="scroll" class="input" :class="{ search_etat: search_etat }" id="inp">
      <div class="ico_main" v-if="geo_can" @click="geo_start">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" /></svg>
      </div>
      
      <input type="text" :placeholder="label" v-$model="search_m">


      <transition name="slide-fade">
      <div class="result_list" v-if="search_etat === true && select.length">
        <div class="elem" v-for="n in select" :key="n.properties.citycode" @click="result(n.properties)">
          <div class="ico">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" /></svg>
          </div>
          <div class="txt">
            <div class="ville">{{ n.properties.city }}</div>
            <div class="details">{{ n.properties.postcode }}, France</div>
          </div>
        </div>
      </div>
      </transition>
    </div>
      <div class="cancel" v-if="fullscreen" @click="$emit('close')">Annuler</div>
      
    </div>
    
    <div class="location" v-if="geo_can" @click="geo_start">
      <div class="img"></div>
      <span>Découvrir les restaurants à proximité</span>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "search_ville",
  data() {
    return {
      search_m: '',
      last_search_m: '',
      select: [],
      
      geo_can: true,
      
      search_etat: false,
      search_interval: null
    }
  },
  props: [ 'label', 'fullscreen' ],
  watch: {
    $ville() {
      this.label = this.$ville
    },
    // search_m(value) {
    //   console.log(value)
    //   if(this.search_m.length > 1) {
    //     this.$http.get('https://geo.api.gouv.fr/communes?limit=10&nom=' + this.search_m).then((response) => {
    //       this.select = response.data
    //       this.last_search_m = this.search_m
    //     }).catch(e => console.log(e))
    //   } else if(this.search_m.length <= 0)
    //     this.select = []
    // }
  },
  created() {
    window.scrollTo(0,0);
    let self = this
    setTimeout(function() {
      if(window.innerWidth > 800)
        document.querySelector('.search_city input').focus()
      self.search_etat = true
    })
    this.search_interval = setInterval(function() {
      if(self.search_m.length > 1) {
        // if(self.isNormalInteger(self.search_m)) {
        //  
        //   self.$http.get('https://api-adresse.data.gouv.fr/search/?q=' + self.search_m + '&type=municipality&limit=10').then((response) => {
        //     self.select = response.data
        //     self.last_search_m = self.search_m
        //   }).catch(e => console.log(e))
        // }
        // else {
          self.$http.get('https://api-adresse.data.gouv.fr/search/?q=' + self.search_m + '&type=municipality&limit=10', {
            transformRequest: [(data, headers) => { delete headers.common.Authorization; return data }]
          }).then((response) => {
            if(response.data.features) 
             self.select = response.data.features
  
            self.last_search_m = self.search_m
          }).catch(e => console.log(e))
        // }
      } else if(self.search_m.length <= 0)
        self.select = []
    }, 500)
    
    // let search = document.querySelector('.search_city input')
    // search.addEventListener('change', function() {
    // 
    // })
    

    window.addEventListener('click', function(e){
      if (document.getElementById('inp') && document.getElementById('inp').contains(e.target))
        self.search_etat = true
      else {
        self.search_etat = false
      }
    });

    if ("geolocation" in navigator) 
      this.geo_can = true
    else 
      this.geo_can = false
    
  },
  beforeDestroy() {
    clearInterval(this.search_interval)
  },
  methods: {
    scroll() {
      if(!this.fullscreen && window.innerWidth < 800) {
        let y = this.getOffset(document.querySelector('.input_block')).top
        window.scrollTo(0, y - 16)
      }
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    },
    isNormalInteger(str) {
      str = str.replace(/^0+/, "") || "0";
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },
    geo_start() {
      let self = this
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log(position.coords.latitude, position.coords.longitude);
        if(position.coords.latitude && position.coords.longitude) {
          self.$http.get('https://api-adresse.data.gouv.fr/reverse/?lat=' + position.coords.latitude + '&lon=' + position.coords.longitude, {
            transformRequest: [(data, headers) => { delete headers.common.Authorization; return data }]
          }).then((response) => {
            console.log(response.data.features[0])
            if(response.data && response.data.features && response.data.features[0] && response.data.features[0].properties && response.data.features[0].properties.city)
              self.result(response.data.features[0].properties)
            else 
              alert("Une erreur s'est produite sur la géolocalisation")
          }).catch(e => console.log(e))
        }
      });
    },
    change() {
     
    },
    result(city) {
      console.log(city)
      this.search_etat = false
      this.$ville = { name: city.city, code: city.citycode, cp: city.postcode, round: 0 }
      this.search_m = ''
      this.select = []
      this.$emit('result', city.city)
    }
  }
}
</script>

<style scoped>
  .search_city { background: #fff; width: 100%; margin-bottom: 16px; display: flex; align-items: center; position: relative;
    flex-direction: column; justify-content: center; z-index: 99;
  }
.search_city .input_block { width: 100%; display: flex; flex-direction: row; align-items: center; color: var(--black-main); position: relative; }
  .search_city .input_block .cancel { font-size: 16px; padding-left: 16px; cursor: pointer }
  .search_city .input { display: flex; align-items: center; width: 100%; background: var(--input); border-radius: 24px; border: 1px solid var(--gris);
    position: relative; flex-direction: row-reverse;  }
  .search_city .input .ico_main { margin: 0 16px; position: absolute; top: 0; bottom: 0; display: flex; align-items: center; cursor: pointer }
  .search_city .input input { width: 100%; font-size: 18px; height: 50px; border: none; background: none; outline: none; padding-right: 50px; padding-left: 24px; transition: all .05s ease-in-out }
  .search_city .input input::placeholder { color: var(--black-sub) }
  /*.search_city .input.search_etat:after { border-bottom: 2px solid #111; position: absolute; bottom: 0; left: 0; right: 0; content: '' }*/
  
  .search_city .result_list { position: absolute; top: 58px; background: #fff; max-height: calc(100vh - 68px); overflow-y: auto; left: -17px; right: -17px;
    overflow-x: hidden; z-index: 3; padding-bottom: 16px;
  }
  .search_city .result_list .elem { display: flex; align-items: center; height: 76px; width: 100%; cursor: pointer; padding-left: 32px; }
  .search_city .result_list .elem:hover { background: #f5f5f5 }
  .search_city .result_list .elem .ico { width: 36px; height: 36px; display: flex; align-items: center; justify-content: center; margin-right: 16px; width: 48px; height: 48px; background: var(--gris); border-radius: 8px }
  .search_city .result_list .elem .ico svg { margin: 0; fill: var(--black-main) }
  .search_city .result_list .elem .txt .ville { font-size: 16px; color: var(--boColor-gris1); padding-bottom: 1px; }
  .search_city .result_list .elem .txt .details { font-size: 14px; color: var(--boColor-gris2) }
  
  .search_city.fullscreen { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 100; max-width: inherit!important; margin: 0!important; padding: 0; background: none;
    justify-content: flex-start; padding: 16px;
  }
  .search_city.fullscreen #search_opaque { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: #fff; }
  .search_city:not(.fullscreen) #search_opaque { display: none }
  
  .search_city .location { width: 100%; position: revert; z-index: 2; display: flex; align-items: center; padding: 16px 16px; cursor: pointer; margin-top: 8px; }
  .search_city .location .img { width: 48px; height: 48px; border-radius: 8px; background: url(../../assets/img/ico/map.png); background-size: contain; flex: 0 0 auto; margin-right: 16px; }
  .search_city .location span { font-size: 16px; line-height: 20px; color: var(--black-sub); font-weight: 400; }
  .search_city .location:hover { background: #f5f5f5; }
  

  @media only screen and (min-width: 1100px) {
    .search_city.fullscreen { padding: 0 35%; justify-content: center; top: 0; }
    .search_city:not(.fullscreen) { justify-content: flex-start; background: none }
    /*.search_city.fullscreen .input { border-radius: 1px }*/
    .search_city.fullscreen .input input { height: 60px; }
    .search_city.fullscreen .result_list { top: 61px; right: 12px; width: auto; position: absolute; max-height: calc(50vh + 30px); padding-top: 8px; }
    .search_city.fullscreen .result_list .elem { padding-left: 16px; }
    .search_city.fullscreen .location { margin-top: 8px; border-radius: 4px; }

    
  }
  
</style>