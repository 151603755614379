<template>
  <router-link v-if="!auto" :to="link" tag="a" @click="to" class="boutique" :class="{ load: load === false }" :data-auto="auto" :data-plan="data.plan_active">
    <div class="img loading_animation" :data-offline="data.online">
      <el-image class="img_load" :src="$img_path + data.img_key" fit="contain" @load="load = true" :alt="data.name"></el-image>
      
      <div class="error" v-if="data.stripe_status && !data.active">
        <svg data-v-423ee37e="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path data-v-423ee37e="" d="M19,1L17.74,3.75L15,5L17.74,6.26L19,9L20.25,6.26L23,5L20.25,3.75M9,4L6.5,9.5L1,12L6.5,14.5L9,20L11.5,14.5L17,12L11.5,9.5M19,15L17.74,17.74L15,19L17.74,20.25L19,23L20.25,20.25L23,19L20.25,17.74"></path></svg>
        Bientôt disponible
      </div>
      <div class="error" v-else-if="!data.online && !data.nextOpen.nohours && !data.nextOpen.open && data.nextOpen.hours">
        <svg class="ico" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z" /></svg>
        <div class="planifier" v-if="data.plan_active">
          <svg data-v-75d7956b="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="19" height="19" viewBox="0 0 24 24"><path data-v-75d7956b="" d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"></path></svg>
          Programmez une commande
        </div>
        Ouvre <template v-if="data.nextOpen.day">{{ data.nextOpen.day }}</template> à {{ data.nextOpen.hours[0] }}
      </div>
      <div class="error" v-else-if="!data.online">
        <svg class="ico" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M17.75,4.09L15.22,6.03L16.13,9.09L13.5,7.28L10.87,9.09L11.78,6.03L9.25,4.09L12.44,4L13.5,1L14.56,4L17.75,4.09M21.25,11L19.61,12.25L20.2,14.23L18.5,13.06L16.8,14.23L17.39,12.25L15.75,11L17.81,10.95L18.5,9L19.19,10.95L21.25,11M18.97,15.95C19.8,15.87 20.69,17.05 20.16,17.8C19.84,18.25 19.5,18.67 19.08,19.07C15.17,23 8.84,23 4.94,19.07C1.03,15.17 1.03,8.83 4.94,4.93C5.34,4.53 5.76,4.17 6.21,3.85C6.96,3.32 8.14,4.21 8.06,5.04C7.79,7.9 8.75,10.87 10.95,13.06C13.14,15.26 16.1,16.22 18.97,15.95M17.33,17.97C14.5,17.81 11.7,16.64 9.53,14.5C7.36,12.31 6.2,9.5 6.04,6.68C3.23,9.82 3.34,14.64 6.35,17.66C9.37,20.67 14.19,20.78 17.33,17.97Z" /></svg>
        <div class="planifier" v-if="data.plan_active">
          <svg data-v-75d7956b="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="19" height="19" viewBox="0 0 24 24"><path data-v-75d7956b="" d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"></path></svg>
          Programmez une commande
        </div>
        Momentanément indisponible
      </div>
      
      


      <div class="nouveau" v-if="data.nouveau">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="18" height="18" viewBox="0 0 24 24"><path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>
        Nouveau
      </div>
    </div>
    <div class="title">
      {{ data.name }}
      <div class="info">
        <span class="">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="18" height="18" viewBox="0 0 24 24"><path d="M19 10C18.44 10 17.91 10.11 17.41 10.28L14.46 4.5H11V6H13.54L14.42 7.72L12 13.13L10.23 8.95C10.5 8.85 10.74 8.58 10.74 8.25C10.74 7.84 10.41 7.5 10 7.5H8C7.58 7.5 7.24 7.84 7.24 8.25S7.58 9 8 9H8.61L10.86 14.25H9.92C9.56 11.85 7.5 10 5 10C2.24 10 0 12.24 0 15S2.24 20 5 20C7.5 20 9.56 18.15 9.92 15.75H12.5L15.29 9.43L16.08 10.96C14.82 11.87 14 13.34 14 15C14 17.76 16.24 20 19 20S24 17.76 24 15 21.76 10 19 10M5 18.5C3.07 18.5 1.5 16.93 1.5 15S3.07 11.5 5 11.5C6.67 11.5 8.07 12.68 8.41 14.25H4V15.75H8.41C8.07 17.32 6.67 18.5 5 18.5M19 18.5C17.07 18.5 15.5 16.93 15.5 15C15.5 13.92 16 12.97 16.77 12.33L18.57 15.85L19.89 15.13L18.1 11.63C18.39 11.56 18.69 11.5 19 11.5C20.93 11.5 22.5 13.07 22.5 15S20.93 18.5 19 18.5Z" /></svg>
<!--          {{ $delayFormatClient(data.delay) }}-->
          <template v-if="data.livraison_prices && $ville.code && data.livraison_prices[$ville.code]">
            Livraison disponible
          </template>
          <template v-else>
            Livraison gratuite
          </template>
        </span>
<!--        <span class="capitalize"> {{ data.locality }}</span>-->
      </div>
    </div>
  </router-link>
  <div v-else @click="to" class="boutique" :class="{ load: load === false }" :data-auto="auto">
    <div class="img loading_animation" :data-offline="data.online">
      <el-image class="img_load" :src="$img_path + data.img" fit="contain" @load="load = true" :alt="data.name"></el-image>
    </div>
    <div class="title">
      {{ data.name }}
      <div class="info">
        <span>{{ data.formatted_address }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vignette",
  data() {
    return {
      load: false,
      link: null
    }
  },
  props: [ 'data', 'auto' ],
  created() {
    if(!this.data.key) 
      this.link = { name: 'boutique_locality', params: { boutique_id: this.data.id, name: this.data.name.replaceAll(' ', '_'), locality: this.$ville.name.replaceAll(' ', '_') } }
    else
      this.link = { name: 'boutique_key', params: { boutique_key: this.data.key } }
  },
  computed: {
    lorem() {
      return 'https://picsum.photos/seed/ ' + this.data.name + '/1920/713'
    }
  },
  methods: {
    to() {
      if(!this.auto)
      this.$router.push({ name: 'boutique_locality', params: { boutique_id: this.data.id, name: this.data.name, locality: this.$ville.name } }) 
    }
  }
}
</script>

<style scoped>

  .boutique { position: relative; display: block; border: none }
  .boutique .img { width: 100%; height: 0; padding-bottom: 47.64%; overflow: hidden; position: relative; border-radius: 16px; }
  /*.boutique:not([data-auto]) .img:not([data-offline]):after { content: 'Momentanément indisponible'; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(10,10,10,.5);*/
  /*  display: flex; align-items: center; justify-content: center; color: #fff; font-size: 18px; font-weight: 200;*/
  /*}*/
  .boutique:not([data-auto]) .img .error { background: rgba(10,10,10,.6); position: absolute; top: 0; right: 0; bottom: 0; left: 0;
    display: flex; align-items: center; justify-content: center; color: #fff; fill: #fff; font-size: 18px; font-weight: 600; padding: 0 30%; text-align: center; flex-direction: column;
  }
  .boutique[data-plan]:not([data-auto]) .img .error { font-size: 16px; padding: 0 10% }
  .boutique[data-plan]:not([data-auto]) .ico { display: none }
  
  .boutique:not([data-auto]) .img .error svg { margin-bottom: 8px; }
  .boutique:not([data-auto]) .img .error span { padding-left: 4px; padding-right: 4px; text-transform: capitalize; }
  .boutique .img_load { width: 100%; position: absolute; }
  .boutique .title { font-size: 20px; line-height: 21px; align-items: flex-start; font-weight: bold; background: #fff; position: static;
    bottom: -32px; margin-left: 0px; display: inline-flex; padding: 14px 16px 0 16px; border-radius: 0 16px 16px 16px; z-index: 5; 
    flex-direction: column; justify-content: center;
  }
  .boutique:before { content: ''; position: absolute; left: 10%; right: 10%; top: 50px; bottom: 0; border-radius: 0; }
  .boutique .title .info { font-weight: 500; color: var(--black-sub); fill: var(--black-sub); font-size: 14px; padding-top: 0px; display: flex; align-items: center; position: relative; top: -2px }
  .boutique .title .info svg { position: relative; top: 3px; margin-right: 2px; }
  /*.boutique .info { padding-left: 20px; padding-top: 20px; display: flex; align-items: center; font-size: 16px; font-weight: 400; color: var(--black-sub) }*/
  /*.boutique .info span { padding-right: 4px; }*/
  .boutique .info span:not(:last-child):after { content: '•'; padding-left: 4px; }
  
  .boutique .planifier { background: #fff; font-size: 14px; color: #111; border-radius: 26px; height: 36px; display: flex; align-items: center; justify-content: center; padding: 0; font-weight: 600;
    width: 242px; fill: #111; margin-bottom: 12px;
  }
  .boutique .planifier svg { position: relative; top: 4px; margin-right: 8px; }
  
  .nouveau { position: absolute; top: 0; right: 0; border-radius: 0 0 0 16px; background: var(--orange); color: #fff; height: 30px; display: flex; align-items: center; padding: 0 12px; 
    fill: #fff; font-weight: 500; font-size: 16px; }
  .nouveau svg { margin-right: 6px; }


  @media only screen and (min-width: 800px) {
    .boutique .title { font-size: 26px; font-weight: 900; bottom: -36px; position: static; }
    .boutique[data-auto] .title { font-size: 20px; }
    .boutique .title .info { padding-top: 3px; top: 0 }
    .boutique[data-plan]:not([data-auto]) .img .error { font-size: 18px; }
    /*.boutique .title { height: 36px; }*/
  }

</style>