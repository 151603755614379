<template>
  <div class="img_load">
    <el-image class="img_load" :src="$img_path + id" :fit="fit" :alt="alt"><div slot="error"></div></el-image>
<!--    <el-image class="img_load" :src="'data:image/jpeg;base64, ' + file" :fit="fit"><div slot="error"></div></el-image>-->
<!--    <img :src="'data:image/jpeg;base64, ' + file" alt="file">-->
  </div>
</template>

<script>
export default {
  name: "img_load",
  props: [ 'fit', 'id', 'alt' ],
  created() {
    // this.$http.get('/img/' + this.id).then((img) => {
    //   this.file = img.data.data
    //   this.load = true
    // }).catch(() => {
    //   this.load = false
    // })
  }
}
</script>

<style scoped>

</style>