<template>
  <div class="loader_box" :data-active="$loader">
    <div class="loader">
      <div class="boxes">
        <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader"
}
</script>

<style scoped>
.loader_box { position: fixed; top: 70px; right: 0; bottom: 0; left: 0; background: rgb(255 255 255 / 82%); z-index: 5001; display: none}
.loader_box[data-active] { display: block; }
.loader { position: fixed; top: 53%; left: 50%; transform: translate(-50%, -50%);
  
}
.boxes {
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.boxes .box:nth-child(1) {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-animation: box1 var(--duration) linear infinite;
  animation: box1 var(--duration) linear infinite;
}
.boxes .box:nth-child(2) {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-animation: box2 var(--duration) linear infinite;
  animation: box2 var(--duration) linear infinite;
}
.boxes .box:nth-child(3) {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
  -webkit-animation: box3 var(--duration) linear infinite;
  animation: box3 var(--duration) linear infinite;
}
.boxes .box:nth-child(4) {
  -webkit-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-animation: box4 var(--duration) linear infinite;
  animation: box4 var(--duration) linear infinite;
}
.boxes .box > div {
  --background: var(--orange);
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}
.boxes .box > div:nth-child(1) {
  --top: 0;
  --left: 0;
}
.boxes .box > div:nth-child(2) {
  --background: #a95217;
  --right: 0;
  --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
  --background: #d66c23;
  --rotateX: -90deg;
}
.boxes .box > div:nth-child(4) {
  --background: #00000026;
  --top: 0;
  --left: 0;
  --translateZ: calc(var(--size) * 2 * -1);
}

@-webkit-keyframes box1 {
  0%,
  50% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
  100% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
  }
}

@keyframes box1 {
  0%,
  50% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
  100% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
  }
}
@-webkit-keyframes box2 {
  0% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
}
@keyframes box2 {
  0% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
}
@-webkit-keyframes box3 {
  0%,
  50% {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  100% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
}
@keyframes box3 {
  0%,
  50% {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
  100% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
  }
}
@-webkit-keyframes box4 {
  0% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
  }
  50% {
    -webkit-transform: translate(200%, 100%);
    transform: translate(200%, 100%);
  }
  100% {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
}
@keyframes box4 {
  0% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
  }
  50% {
    -webkit-transform: translate(200%, 100%);
    transform: translate(200%, 100%);
  }
  100% {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
  }
}

</style>