import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// process.env.HTTPS = true;

Vue.config.productionTip = false




// import VFacebookLogin from 'vue-facebook-login-component'
// Vue.component("VFacebookLogin", VFacebookLogin);

// import OtpInput from "@bachdgvn/vue-otp-input";
// Vue.component("v-otp-input", OtpInput);

// import VueTelInput from 'vue-tel-input'
// Vue.use(VueTelInput)

import "regenerator-runtime";

// import FBControl from 'vue-fbcontrol'
// Vue.use(FBControl, { appId: '311448299062536', version: 'v8.0' })

import axios from 'axios'
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios, axios)
// Vue.use(axios)




import fuze from 'fuse.js'
import detectOverflow from 'detect-element-overflow'
// import lax from "lax.js";

// Vue.prototype.$lax = lax;
// Vue.prototype.$marked = marked
Vue.prototype.$http = axios
Vue.prototype.$fuze = fuze
Vue.prototype.$detectOverflow = detectOverflow

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import 'dayjs/locale/fr'


dayjs.locale('fr')
Vue.prototype.$dayjs = dayjs

Vue.filter("from", function(value) {
  return dayjs().to(value);
});
Vue.filter("formatday", function(value, arg) {
  return dayjs(value).format(arg)
});

Vue.prototype.$img_path = process.env.VUE_APP_API + '/img/'
Vue.prototype.$api_ip = process.env.VUE_APP_API + '/'
Vue.prototype.$static_patch = process.env.VUE_APP_STATIC
// Vue.prototype.$api_ip = 'http://127.0.0.1:4010/'
// Vue.prototype.$static_patch = 'http://127.0.0.1:4010'

axios.defaults.baseURL = process.env.VUE_APP_API
// axios.defaults.baseURL = Vue.prototype.$api_ip;

Vue.prototype.$activity = [
    'Burgers', 'Pizza / Italien', 'Kebab', 'Sandwicheries / Salades', 'Cuisine du monde', 'Fruits de mer', 'Japonais', 'Chinois', 'Thaïlandais', 'Crêperies', 'Traditionnel', 'Gastronomique', 'Indien / Pakistanais', 'Libanais', 'Mexicain', 'Moléculaire', 'Autre'
]




// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
// Vue.use(LottieAnimation);


let numeral = require("numeral");
numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ","
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€"
  }
});
Vue.filter("formatNumber", function(value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatNumberdecimal", function(value) {
  return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBqQojv15y7c3Wlk4h74mNhPkxEk8A1ngc",
    libraries: "places",
    region: 'FR',
    language: 'fr',
  }
});


let globalData = new Vue({
  data: { $panier_indicator: 0, $boutique_id: null, $ville: '', $load: null, $loader: false }
});
Vue.mixin({
  computed: {
    $load: {
      get: function () { return globalData.$data.$load },
      set: function (newValue) { globalData.$data.$load = newValue; }
    }
  }
})
Vue.mixin({
  computed: {
    $panier_indicator: {
      get: function () { return globalData.$data.$panier_indicator },
      set: function (newValue) { globalData.$data.$panier_indicator = newValue; }
    }
  }
})
Vue.mixin({
  computed: {
    $loader: {
      get: function () { return globalData.$data.$loader },
      set: function (newValue) { globalData.$data.$loader = newValue; }
    }
  }
})
// Vue.mixin({
//   computed: {
//     $bo_boutique_id: {
//       get: function () { return globalData.$data.$bo_boutique_id },
//       set: function (newValue) { globalData.$data.$bo_boutique_id = newValue; }
//     }
//   }
// })
Vue.mixin({
  computed: {
    $boutique_id: {
      get: function () { return globalData.$data.$boutique_id },
      set: function (newValue) { globalData.$data.$boutique_id = newValue; }
    }
  }
})
Vue.mixin({
  computed: {
    $ville: {
      get: function () {
        return JSON.parse(globalData.$data.$ville)
      },
      set: function (newValue) { 
        globalData.$data.$ville = JSON.stringify(newValue)
        localStorage.setItem('ville_tab', JSON.stringify(newValue))
      }
    }
  }
})
globalData.$data.$ville = localStorage.getItem('ville_tab');
// localStorage.removeItem('ville')
//
// globalData.$data.$ville = JSON.parse(globalData.$data.$ville)

import Compressor from 'compressorjs';
// Vue.prototype.$Compressor = Compressor

Vue.prototype.$upload_img = function(e, h = 500, tag = 'produit') {
  return new Promise(function(resolve, reject) {
    const file = e.target.files[0];
    if (!file) return;
  
    new Compressor(file, {
      quality: 0.8,
      mimeType: 'img/jpeg',
      strict: true,
      height: h,
      success(result) {
        let formData = new FormData();
        formData.append('file', result, result.name);
  
        axios.post('/img/' + tag, formData ).then((upload_r) => {
          resolve({
            key: upload_r.data.key,
            blob: URL.createObjectURL(result)
          })
        }).catch((e) => reject(e))
      },
      error(err) {
        console.log(err.message);
      },
    });
  })
}




Vue.prototype.$gener_boutique_id = function(user) {
  if(user.boutiques.length === 0)
    localStorage.removeItem('bo_boutique_id');
  
  let bo_boutique_id = localStorage.getItem("bo_boutique_id");
  if (user.boutiques && !bo_boutique_id && user.boutiques[0]) localStorage.setItem("bo_boutique_id", user.boutiques[0])
  if (bo_boutique_id && !user.boutiques) localStorage.removeItem("bo_boutique_id")
  // if (bo_boutique_id && user.boutiques && user.boutiques[0] && user.boutiques[0] !== bo_boutique_id) localStorage.removeItem("bo_boutique_id")
}

Vue.directive('$model', {
  bind: function (el, binding, vnode) {
    el.oninput = () => (vnode.context[binding.expression] = el.value)
  }
})


// import VueTour from 'vue-tour'
// require('vue-tour/dist/vue-tour.css')
// Vue.use(VueTour)

// import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/css/element.css';
import { Form, FormItem, Input, Image, Select, Option, Steps, Step, InputNumber, Upload, Switch, Loading, Message, Notification, Autocomplete, Dropdown, DropdownMenu, DropdownItem, Popover,
  TimePicker, TimeSelect, Progress, Tooltip, Rate, Radio, CheckboxGroup, Checkbox, Collapse, CollapseItem, Table, TableColumn
} from 'element-ui';

import lang from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
locale.use(lang);

Vue.use(Form)
Vue.use(FormItem, {})
Vue.use(Image)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Steps)
Vue.use(Step)
Vue.use(InputNumber)
Vue.use(Upload)
Vue.use(Switch)
Vue.use(Loading)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(TimePicker)
Vue.use(TimeSelect)
Vue.use(Progress)
Vue.use(Tooltip)
Vue.use(Rate)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Table)
Vue.use(TableColumn)
// Vue.use(Message)

Vue.prototype.$projectId = 3

Vue.prototype.$message = Message
Vue.prototype.$notify = Notification;
Vue.prototype.$notifyOffest = 70

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)
Vue.prototype.$delayFormat = (delay) => {
  if(delay < 60) {
    return delay + ' minutes'
  }
  else {
    return delay / 60 + ' heure'
  }
}
Vue.prototype.$delayFormatClient = (delay) => {
  return delay + '-' + (delay + 10) + ' min'
}
Vue.prototype.$round = (num, key = 10) => {
  return Math.ceil(num / key ) * key;
}
Vue.prototype.$roundHour = (at, delay, key = 10) => {
  let hour = dayjs(at).add(delay + 10, 'minutes').format('HH')
  let minutes = dayjs(at).add(delay + 10, 'minutes').format('mm')
  
  let minutes_f = Math.ceil(minutes / key ) * key
  if(minutes_f < 60) {
    return hour + 'h' + minutes_f
  }
  else {
    return parseInt(hour) + 1 + 'h00'
  }
}



import img_load from '@/components/global/img_load'
Vue.component('img_load', img_load)

import retour from '@/components/bo/retour'
Vue.component('bo_retour', retour)

import title_cat from '@/components/global/title_cat'
Vue.component('title_cat', title_cat)

import input_solo from '@/components/global/input_solo'
Vue.component('input_solo', input_solo)

import footer_b from '@/components/global/footer_b'
Vue.component('footer_b', footer_b)

import button_plus from '@/components/global/button_plus'
Vue.component('button_plus', button_plus)

import number from '@/components/global/number'
Vue.component('number', number)

// Vue.prototype.$loading = Loading.service;
// Vue.use(FBControl, { appId: '2706636502953827', version: 'v7.0' })


import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";

let socket;

if (process.env.NODE_ENV === "production") {
  socket = io("https://api.maboutique.click", {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity
  });
} else {
  socket = io('http://192.168.1.60:4010', {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax : 5000,
    reconnectionAttempts: Infinity
  });
  
  socket.on('disconnect', () => {
    setTimeout(() => {
      console.log('Socket connect attempt')
      socket.connect()
    }, 5000)
  })
  // socket = io("https://dev.pleinair.fun");
}
Vue.use(VueSocketIOExt, socket);

import Meta from 'vue-meta'
Vue.use(Meta)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
