import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { checkCity: true }
  },
  {
    path: '/ville/:code/:cp/:name',
    name: 'ville',
    component: Home,
  },
  {
    path: '/livreur',
    name: 'livreur',
    component: () => import('../views/livreur/home.vue'),
    meta: { title: 'Interface livreur' }
  },
  // {
  //   path: '/votre-boutique',
  //   name: 'votreboutique',
  //   component: () => import('../views/landing/votreboutique.vue'),
  // },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('../views/bo/produit_add_edit.vue'),
  // },
  {
    path: '/votre-resto',
    name: 'votreresto',
    component: () => import('../views/landing/votre-resto'),
    meta: { scrollToTop: true, title: 'Votre resto en quelques clics' }
  },
  {
    path: '/qui-sommes-nous',
    name: 'quisommesnous',
    component: () => import('../views/landing/quisommesnous'),
    meta: { scrollToTop: true, title: 'Qui sommes nous' }
  },
  {
    path: '/boutique/creation',
    name: 'boutique_creation',
    component: () => import('../views/bo/create_buisness.vue'),
    meta: { title: 'Créer ma boutique', requiresAuth: true, scrollToTop: true }
  },
  {
    path: '/produit/:product_id/:name',
    name: 'produit',
    meta: { nav_hide_mobile: true },
    component: () => import('../views/produit.vue')
  },
  {
    path: '/produit/:product_id/:name/:view',
    name: 'produit',
    component: () => import('../views/produit.vue')
  },
  {
    path: '/commande/:commande_id',
    name: 'commande_view',
    component: () => import('../views/commande.vue')
  },
  {
    path: '/user/login',
    name: 'user_login',
    meta: { requiresNotAuth: true, scrollToTop: true, title: 'Connexion' },
    component: () => import('../views/user/login.vue')
  },
  {
    path: '/user/create',
    name: 'user_create',
    meta: { requiresNotAuth: true, scrollToTop: true, title: 'Créer mon compte' },
    component: () => import('../views/user/create.vue')
  },
  {
    path: '/user/mail_recovery_send',
    name: 'user_mail_recovery_send',
    meta: { requiresNotAuth: true,
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ] },
    component: () => import('../views/user/mail_recovery_send.vue')
  },
  {
    path: '/user/mail_recovery_update/:key',
    name: 'user_mail_recovery_update',
    meta: { requiresNotAuth: true,
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ] },
    component: () => import('../views/user/mail_recovery_update.vue')
  },
  {
    path: '/user/compte', name: 'compte', component: () => import('../views/user/compte/index.vue'),
    meta: { title: 'Mon Compte ', requiresAuth: true },
    children: [
      {
        path: 'commandes',
        name: 'user_compte_commandes',
        component: () => import('../views/user/compte/commandes'),
        meta: { title: 'Mes commandes' }
      },
      {
        path: 'password',
        name: 'user_compte_password',
        component: () => import('../views/user/compte/password'),
        meta: { title: 'Changer mon mot de passe' }
      }
    ]
  },
    
    
  {
    path: '/superbo', name: 'superbo', component: () => import('../views/superbo/template.vue'),
    meta: { title: 'Super BO ', requiresAuth: true,
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ] },
    children: [
      // {
      //   path: 'commandes',
      //   name: 'user_compte_commandes',
      //   component: () => import('../views/user/compte/commandes')
      // }
    ]
  },
    
    
    
  {
    path: '/backoffice/configuration', name: 'bo_config', component: () => import('../views/bo/config/index.vue'),
    meta: { title: 'Backoffice ', bo_config: true, requiresAuth: true,
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ] },
    children: [
      {
        path: 'boutique',
        name: 'bo_config_boutique',
        meta: { bo: true, bo_config: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ]},
        component: () => import('../views/bo/config/boutique')
      },
      {
        path: 'horaires',
        name: 'bo_config_horaires',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/horaires')
      },
      {
        path: 'assistance',
        name: 'bo_config_assistance',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/assistance')
      },
      {
        path: 'paiements',
        name: 'bo_config_paiements',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/paiements')
      },
      {
        path: 'factures',
        name: 'bo_config_factures',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/factures')
      },
      {
        path: 'abonnement',
        name: 'bo_config_abonnement',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/abonnement')
      },
      {
        path: 'resto_livraison',
        name: 'bo_resto_livraison',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/resto_livraison')
      },
      {
        path: 'livreurs',
        name: 'bo_config_livreurs',
        meta: { bo: true, bo_config: true, scrollToTop: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/config/livreurs')
      }
    ]
  },
    
    
    
  {
    path: '/backoffice',
    name: 'bo',
    component: () => import('../views/bo/index.vue'),
    meta: { title: 'Backoffice ', requiresAuth: true,
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ] },
    children: [
      {
        path: 'statistiques',
        name: 'bo_stats',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/stats')
      },
      {
        path: 'produits',
        name: 'bo_produits',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/produits')
      },
      {
        path: 'produits/categorie/:categorie',
        name: 'bo_produits_in_category',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/produits_in_category')
      },
      {
        path: 'commandes/enattente',
        name: 'bo_commandes_enattente',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/commandes_enattente')
      },
      {
        path: 'commandes/encours',
        name: 'bo_commandes_encours',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/commandes_encours')
      },
      {
        path: 'commandes/aexpedier',
        name: 'bo_commandes_aexpedier',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/commandes_aexpedier')
      },
      {
        path: 'produit_steps/:categorie',
        name: 'bo_produit_add',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/produit_add_edit')
      },
      {
        path: 'produit_steps/edit/:product_id',
        name: 'bo_produit_edit',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/produit_add_edit')
      },
      {
        path: 'categorie/add',
        name: 'bo_categorie_add',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/categorie/add')
      },
      {
        path: 'categorie/delete/:categorie_name',
        name: 'bo_categorie_delete',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/categorie/delete')
      },
      {
        path: 'categorie/edit/:categorie_name',
        name: 'bo_categorie_edit',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/categorie/edit')
      },
      {
        path: 'options/edit/:option_id',
        name: 'bo_options_edit',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/options/edit')
      },
      {
        path: 'options/add',
        name: 'bo_options_add',
        meta: { bo: true,
          metaTags: [
            {
              name: 'robots',
              content: 'noindex'
            }
          ] },
        component: () => import('../views/bo/options/edit')
      }
    ]
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('../views/legal/index'),
    children: [
        {
          path: 'cgu', name: 'legal_cgu', component: () => import('../views/legal/cgu'),
          meta: { scrollToTop: true,
            metaTags: [
              {
                name: 'robots',
                content: 'noindex'
              }
            ] }
        }
    ]
  },
  {
    path: '/resto/:boutique_id',
    name: 'boutique_id',
    meta: { noTop: true },
    component: () => import('../views/boutique.vue')
  },
  {
    path: '/resto/:boutique_id/:category',
    name: 'restoCategory',
    component: () => import('../views/boutique.vue'),
    meta: { noTop: true }
  },
  {
    path: '/:boutique_id/panier',
    name: 'panier',
    component: () => import('../views/panier/home'),
    meta: { scrollToTop: true,
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ] }
  },
  {
    path: '/:boutique_id/:name/:locality',
    name: 'boutique_locality',
    meta: { loader:true },
    component: () => import('../views/boutique.vue')
  },
  {
    path: '/:boutique_id/:name',
    name: 'boutique',
    meta: { loader:true },
    component: () => import('../views/boutique.vue')
  },
  {
    path: '/:boutique_key',
    name: 'boutique_key',
    component: () => import('../views/boutique.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior (to, from, savedPosition) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       if (savedPosition) {
  //         resolve( savedPosition)
  //       } else {
  //         resolve( { x: 0, y: 0 })
  //       }
  //     }, 300)
  //   })
  // 
  // },
  scrollBehavior (to, from, savedPosition) {
    if (to.matched.some((m) => m.meta.noTop)) {
      return savedPosition
    }
    else if (to.matched.some((m) => m.meta.scrollToTop)) {
      return { x: 0, y: 0}
    }
    else
      return !savedPosition ? { x: 0, y: 0 } : savedPosition
  },
  // scrollBehavior: (to, from, savedPosition) => new Promise((resolve) => {
  //   const position = savedPosition || {};
  //   if (!savedPosition) {
  //     if (to.hash) {
  //       position.selector = to.hash;
  //     }
  //     if (to.matched.some((m) => m.meta.scrollToTop)) {
  //       position.x = 0;
  //       position.y = 0;
  //       router.app.$nextTick(() => resolve(position));
  //     }
  //   }
  //  
  //   router.app.$root.$once('triggerScroll', () => {
  //     if(Object.keys(position).length === 0) {
  //       resolve({x: 0, y: 0})
  //     }
  //     else
  //       router.app.$nextTick(() => {
  //         console.log(position)
  //         resolve(position)
  //       });
  //   });
  // }),
  routes,
})







router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  // if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));

  next();
});








router.beforeEach((to, from, next) => {
  //  if(to.matched.some(record => record.meta.onlyNotBoutique)) {
  //   console.log('onlyNotBoutique')
  //   if (localStorage.getItem('bo_boutique_id') != null) {
  //     next({
  //       path: '/',
  //     })
  //   }
  // }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      localStorage.setItem("beforeLogin", to.name);
      next({
        path: '/user/login',
      })
    }
    else {
      next()
    }
  }
  else if(to.matched.some(record => record.meta.requiresNotAuth)) {
    if (localStorage.getItem('token') != null) {
      next({
        path: '/',
      })
    }
    else {
      next()
    }
  }
  else if(to.matched.some(record => record.meta.checkCity)) {
    if (localStorage.getItem('ville_tab') != null) {
      let ville = JSON.parse(localStorage.getItem('ville_tab'))
      if(ville.name && ville.code && ville.cp) {
        console.log('redirect')
        next({
          name: 'ville',
          params: {
            name: ville.name,
            cp: ville.cp,
            code: ville.code
          }
        })
      }
      else {
        next()
      }
      // next()
    }
    else {
      next()
    }
  } else {
    next()
  }
})



export default router
