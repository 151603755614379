<template>
  <transition name="fade">
  <div class="maj" v-if="show || loader">
    <template v-if="loader">
<!--      <div class="container">-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--        <span class="dots"></span>-->
<!--      </div>-->
<!--      <logo class="logo" width="128"></logo>-->
      Mise à jour en cours
    </template>
  </div>
  </transition>
</template>

<script>
export default {
  name: "maj",
  data() {
    return {
      loader: false,
      show: false,
      first: false,
    }
  },
  props: [ 'display' ],
  created() {
    this.sync()
    document.addEventListener("visibilitychange", () => {
      if(!document.hidden) {
        this.sync()
        console.log('check')
      }
    })
  },
  methods: {
    sync() {
      if ('serviceWorker' in navigator) {
        if(this.display && !this.first)
          this.show = true
          
        this.first = true
        this.$http.get('https://api.pleitek.com/creativeapi/project/version').then((data) => {
          const { version } = data.data

          let thisVersion = localStorage.getItem('appVersion')
          // if(!thisVersion) {
          //   this.show = false
          //   localStorage.setItem('appVersion', version)
          // }
          if(!thisVersion || thisVersion !== version) {
            this.loader = true
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              registrations.map(r => {
                r.unregister()
              })
            })
            localStorage.setItem('appVersion', version)
            setTimeout(function () {

              window.location.reload(true)
            }, 1000)
          }
          else {
            this.show = false
          }
        }).catch(() => {
          this.show = false
        })
      }
    }
  }
}
</script>

<style scoped>
  .maj { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #fff; display: flex; flex-direction: column; align-items: center; justify-content: center;
    font-weight: 500; font-size: 1.4rem; color: #777; color: var(--orange); animation: load 1s; z-index: 999999999;
  }

  .logo { margin-bottom: 16px;  }
  
  @keyframes load {
    0% { filter: grayscale(100%) }
    100% { filter: grayscale(0%) }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .33s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .container {
    display: inline-block;
    position: relative;
  }

  .dots {
    display: inline-block;
    position: relative;
    vertical-align: middle; margin: 0 16px 100px 16px;
  }
  .dots:not(:last-child) {
    margin-right: 9px;
  }
  .dots:before, .dots:after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
  }
  .dots:nth-child(1):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
    background-color: var(--vert);
  }
  .dots:nth-child(1):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
    background-color: #777;
  }
  .dots:nth-child(2):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -1.8s;
    animation-delay: -1.8s;
    background-color: var(--vert);
  }
  .dots:nth-child(2):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -1.8s;
    animation-delay: -1.8s;
    background-color: #777;
  }
  .dots:nth-child(3):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -2.7s;
    animation-delay: -2.7s;
    background-color: var(--vert);
  }
  .dots:nth-child(3):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -2.7s;
    animation-delay: -2.7s;
    background-color: #777;
  }
  .dots:nth-child(4):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -3.6s;
    animation-delay: -3.6s;
    background-color: var(--vert);
  }
  .dots:nth-child(4):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -3.6s;
    animation-delay: -3.6s;
    background-color: #777;
  }
  .dots:nth-child(5):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -4.5s;
    animation-delay: -4.5s;
    background-color: var(--vert);
  }
  .dots:nth-child(5):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -4.5s;
    animation-delay: -4.5s;
    background-color: #777;
  }
  .dots:nth-child(6):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -5.4s;
    animation-delay: -5.4s;
    background-color: var(--vert);
  }
  .dots:nth-child(6):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -5.4s;
    animation-delay: -5.4s;
    background-color: #777;
  }
  .dots:nth-child(7):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -6.3s;
    animation-delay: -6.3s;
    background-color: var(--vert);
  }
  .dots:nth-child(7):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -6.3s;
    animation-delay: -6.3s;
    background-color: #777;
  }
  .dots:nth-child(8):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -7.2s;
    animation-delay: -7.2s;
    background-color: var(--vert);
  }
  .dots:nth-child(8):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -7.2s;
    animation-delay: -7.2s;
    background-color: #777;
  }
  .dots:nth-child(9):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -8.1s;
    animation-delay: -8.1s;
    background-color: var(--vert);
  }
  .dots:nth-child(9):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -8.1s;
    animation-delay: -8.1s;
    background-color: #777;
  }
  .dots:nth-child(10):before {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-animation: animBefore 1s linear infinite;
    animation: animBefore 1s linear infinite;
    -webkit-animation-delay: -9s;
    animation-delay: -9s;
    background-color: var(--vert);
  }
  .dots:nth-child(10):after {
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-animation: animAfter 1s linear infinite;
    animation: animAfter 1s linear infinite;
    -webkit-animation-delay: -9s;
    animation-delay: -9s;
    background-color: #777;
  }

  @-webkit-keyframes animBefore {
    0% {
      -webkit-transform: scale(1) translateY(-200%);
      z-index: 1;
    }
    25% {
      -webkit-transform: scale(1.3) translateY(0);
      z-index: 1;
    }
    50% {
      -webkit-transform: scale(1) translateY(200%);
      z-index: -1;
    }
    75% {
      -webkit-transform: scale(0.7) translateY(0);
      z-index: -1;
    }
    100% {
      -webkit-transform: scale(1) translateY(-200%);
      z-index: -1;
    }
  }
  @keyframes animBefore {
    0% {
      transform: scale(1) translateY(-200%);
      z-index: 1;
    }
    25% {
      transform: scale(1.3) translateY(0);
      z-index: 1;
    }
    50% {
      transform: scale(1) translateY(200%);
      z-index: -1;
    }
    75% {
      transform: scale(0.7) translateY(0);
      z-index: -1;
    }
    100% {
      transform: scale(1) translateY(-200%);
      z-index: -1;
    }
  }
  @-webkit-keyframes animAfter {
    0% {
      -webkit-transform: scale(1) translateY(200%);
      z-index: -1;
    }
    25% {
      -webkit-transform: scale(0.7) translateY(0);
      z-index: -1;
    }
    50% {
      -webkit-transform: scale(1) translateY(-200%);
      z-index: 1;
    }
    75% {
      -webkit-transform: scale(1.3) translateY(0);
      z-index: 1;
    }
    100% {
      -webkit-transform: scale(1) translateY(200%);
      z-index: 1;
    }
  }
  @keyframes animAfter {
    0% {
      transform: scale(1) translateY(200%);
      z-index: -1;
    }
    25% {
      transform: scale(0.7) translateY(0);
      z-index: -1;
    }
    50% {
      transform: scale(1) translateY(-200%);
      z-index: 1;
    }
    75% {
      transform: scale(1.3) translateY(0);
      z-index: 1;
    }
    100% {
      transform: scale(1) translateY(200%);
      z-index: 1;
    }
  }
</style>