
// import router from "../../router";
import axios from 'axios'
import router from '@/router'


const state = {
    boutique_id: null,
    boutique: null,
    produits: null,
    options: []
}

// getters
const getters = {
    
};

// actions
const actions = {
    sync(store, id) {
        // router.push('/')
        if(!this.state.boutique.boutique || parseInt(this.state.boutique.boutique.id) !== parseInt(id)) {
            axios.get('/boutique/' + id).then((d) => {
                if(d.status === 200)
                    store.commit("SYNC", d.data);
                else
                    router.push('/')
            }).catch(() => { router.push('/') })
            axios.get('/boutique/' + id + '/options').then((d) => {
                store.commit("OPTIONS", d.data);
            }).catch(() => { router.push('/') })
        }
    },
    syncKey(store, key) {
        if(!this.state.boutique.boutique || this.state.boutique.boutique.key !== key) {
            axios.get('/boutique/key/' + key).then((d) => {
                if(d.status === 200)
                    store.commit("SYNC", d.data);
                else
                    router.push('/')
            }).catch(() => { router.push('/') })
            axios.get('/boutique/key/' + key + '/options').then((d) => {
                store.commit("OPTIONS", d.data);
            }).catch(() => { router.push('/') })
        }
    },
    sync_force(store, id) {
        axios.get('/boutique/' + id).then((d) => {
            store.commit("SYNC", d.data);
        })
        axios.get('/boutique/' + id + '/options').then((d) => {
            store.commit("OPTIONS", d.data);
        })
    }
};

// mutations
const mutations = {
    SYNC(state, data) {
        state.boutique = data.boutique;
        state.produits = data.produits;
        // state.boutique_id = data.boutique.id
    },
    OPTIONS(state, data) {
        state.options = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
