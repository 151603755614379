// import router from "../../router";
import axios from 'axios'

const state = {
    boutique: {},
    commandes: {},
}

// getters
const getters = {
    // getBoutique: (state) => state.boutique,
    alert_stock: (state) => {
        let alert_stock = 0
        for(let n of state.boutique.produits) {
            if(n.alert_stock <= 5 && n.alert_stock !== false && n.stock_suivi) {
                alert_stock++
            }
        }
        return alert_stock
    },
    by_category: state => categorie => state.boutique.produits.filter(produit => {
        return produit.categorie === categorie;
    })
};

// actions
const actions = {
    sync(store) {
        axios.get('/bo/boutique/').then((d) => {
            store.commit("SYNC", d.data);

            // let commandes = {}
            // axios.get('/bo/boutique/' + id + '/commandes/waiting').then((response) => {
            //     commandes.waiting = response.data
            //     axios.get('/bo/boutique/' + id + '/commandes/processing').then((response) => {
            //         commandes.processing = response.data
            //         axios.get('/bo/boutique/' + id + '/commandes/sent').then((response) => {
            //             commandes.sent = response.data
            //             store.commit('SYNC_COMMANDES', commandes)
            //         })
            //     })
            // })
        })
    },
    sync_commandes(store) {
        let commandes = {}
        axios.get('/bo/boutique/commandes/waiting').then((response) => {
            commandes.waiting = response.data
            axios.get('/bo/boutique/commandes/processing').then((response) => {
                commandes.processing = response.data
                axios.get('/bo/boutique/commandes/sent').then((response) => {
                    commandes.sent = response.data
                    commandes.all = commandes.waiting.concat(commandes.processing).concat(commandes.sent)
                    store.commit('GET_COMMANDES', commandes)
                }) 
            })
        })
    }
};

// mutations
const mutations = {
    SYNC(state, data) {
        state.boutique = data;
    },
    GET_COMMANDES(state, data) {
        state.commandes = data
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
