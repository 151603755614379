<template>
  <div class="number" :class="[ { variation: variation === true }, { edit_active: edit !== null } ]" @click="edit_set()">
    
    <div v-if="variation" class="variation_first"></div>


<!--    <el-tooltip  class="item" effect="dark" :content="(oldValue ? oldValue : 0) + '€'" placement="top">-->
    <div class="num_txt" v-if="edit_etat === false">
      
    <div v-if="round === 'formatNumber'" class="num" :class="color_set(color_val)">{{ value | formatNumber }} <span v-if="euro" class="eur">€</span></div>
    <div v-else-if="round === 'formatNumberdecimal'" class="num" :class="color_set(color_val)">{{ value | formatNumberdecimal }} <span v-if="euro" class="eur">€</span></div>
    <div v-else-if="round === 'formatNumberdecimalsimple'" class="num" :class="color_set(color_val)">{{ value | formatNumberdecimalsimple }}<span v-if="euro" class="eur">€</span></div>
    </div>
    <div class="num_form" v-if="edit_etat === true">
      <input ref="input" :type="edit_type" v-model="value">
    </div>
<!--    </el-tooltip>-->


    <el-tooltip v-if="variation" class="item" effect="dark" :content="(variation_val | formatNumber) + '%'" placement="top">
      <svg v-if="Math.sign(variation_val) !== -1 && variation_val !== 0" class="green ico_trans" :title="variation_val | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
      <svg v-else-if="variation_val !== 0" class="red ico_trans" style="transform: rotate(180deg)" :title="variation_val | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
      <svg v-else class="ico_trans" style="transform: rotate(90deg)" :title="variation_val | formatNumber" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" /></svg>
    </el-tooltip>
    
  </div>
</template>

<script>
export default {
  name: "order_arrow",
  data() {
    return {
      variation_val: 0,
      color_val: 0,

      edit_etat: false
    }
  },
  props: {
    value: [String, Number],
    oldValue: String,
    round: String,
    euro: Boolean,
    variation: Boolean,
    color: Boolean,
    edit: {
      type: String,
      default: null
    },
    edit_type: {
      type: String,
      default: null
    },
    edit_key: {
      type: String,
      default: null
    }
  },
  // props: [ 'value', 'oldValue', 'round', 'euro', 'variation', 'color', 'edit'],
  created() {
    let self = this
    this.calc_variation()

    if(this.color === true) this.color_val = this.value
    
    this.$forceUpdate();


    document.addEventListener("keypress", function(e) {
      if (e.key === "Enter") {
        self.edit_submit()
      }

    })
    // this.value = this.$options.filters[this.round](this.value)
  },
  watch: {
    oldValue: function() { // watch it
     this.calc_variation()
    },
    value: function() { // watch it
     this.calc_variation()
    }
  },
  methods: {
    calc_variation() {
      let last1 = parseFloat(this.oldValue)
      let last2 = parseFloat(this.value)

      this.variation_val = ((last2 - last1) / Math.abs(last1)) * 100
      if(!this.variation_val)
        this.variation_val = 0
      if (this.variation === true) this.color_val = this.variation_val
    },
    color_set(n) {
      if(Math.sign(n) === -1) return 'red'
      else if(n > 0) return 'green'
    },
    edit_set() {
      if(this.edit === null) return
      this.edit_etat = true
      let self = this
      setTimeout(function() {
        self.$refs['input'].focus()
        self.$refs['input'].select()
      }, 10)
    },
    edit_submit() {
      if(this.edit === null || this.edit_etat === false) return
      
      
      this.edit_etat = false
      this.axios.post('/editvalue', {
        type: this.edit,
        key: this.edit_key,
        value: this.value
      }).then((response) => {
        this.$emit('refresh')
        console.log(response)
      })
      
    },
  }
}
</script>

<style scoped>
  .number { display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; position: relative; font-weight: 500; }
  .number.variation { justify-content: space-between; }
  .number .variation_first { width: 34px; }
  .number .ico_trans { margin-right: 10px; fill: #aaa }
  .number .eur { font-size: 14px }
  .number .num { text-align: center }
  /*.order_arraw { width: 50px; height: 50px; }*/


  .number.edit_active:hover { border: 3px solid #4086fd; position: relative; cursor: pointer; }
  .number.edit_active:before { content: 'notes'; font-family: "Material Icons"; font-size: 14px; color: #fff; display: flex; align-items: center; justify-content: center; border-radius: 100%;
    background: #4086fd; width: 20px; height: 20px; position: absolute; z-index: 2; right: -8px; bottom: -8px; opacity: 0; transition: all .02s ease-in-out  }
  .number.edit_active:hover:before { opacity: 1; }
  /*.number.edit:before { opacity: 1; }*/
  /*.number.edit { border: 3px solid #4086fd; position: relative; cursor: pointer; }*/
  
  input { padding: inherit; margin: inherit; border: none!important; background: none!important; font: inherit; color: inherit; height: 100%; width: 100%; min-width: 100%; max-width: 100%; outline: none;
    text-align: center; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
  }


  @media only screen and (min-width: 800px) {


    .number .eur { font-size: 0.7vw }
  }
  
</style>