<template>
  <div class="introOneMobile" @click="$emit('close')">
    <div class="top">
      
    </div>
    <div class="bottom">
      
      <div class="text">
        <b>MonResto<span>.click</span></b> <span class="txt"> La Marketplace <span>Resto-Friendly</span> </span>
      </div>
      <button>Trouver mon resto</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "intro"
}
</script>

<style scoped>
  .introOneMobile { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 500; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;
    text-align: center; 
  }
  .introOneMobile .text { padding: 0vh 16px 0vh 16px; font-size: 20px; line-height: 26px; margin-bottom: 7.5vh;  }
  .introOneMobile .text b { display: block; font-size: 35px; margin-bottom: 26px; }
  .introOneMobile .text b span { color: var(--orange) }
  .introOneMobile .text .txt { color: var(--black-sub); font-size: 20px; line-height: 26px; display: flex; flex-direction: column }
  .introOneMobile .top { background: url(../../assets/img/illu/intro/burger.svg) center 100% no-repeat #ffebd3; background-size: 40vh; height: 60vh; width: 100%; }
  .introOneMobile .bottom { margin-top: 3vh; flex: 0 0 auto; height: 40vh; width: 100%; display: flex; justify-content: flex-end; flex-direction: column; align-items: center; position: relative; }
  /*.introOneMobile .bottom:before { height: 80px; border-radius: 36px 36px 0 0; width: 100%; content: ''; position: absolute; top: -60px; left: 0; right: 0; background: #fff; }*/
  .introOneMobile button { margin-bottom: 9vh; flex: 0 0 auto; background: var(--linear-orange); border-radius: 50px; height: 50px; }
</style>