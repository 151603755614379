<template>

  <div class="faq" :data-open="open">
    <div class="t" @click="open = !open">
      Questions fréquentes
      <i class="el-icon-arrow-right"></i>
    </div>
    <div class="f">
      <el-collapse>
        <el-collapse-item title="Pourquoi commander sur Monresto.click ?">
          <div>
            <ul>
              <li><b>Vous trouverez sur MonResto.click un panel de restaurants </b>
                ne figurant pas sur les sites traditionnels.
              </li>
              <li><b>Les restaurateurs présents sur notre marketplace sont engagés par une charte de qualité.</b>
                L’acceptation de cette charte vous garantit une plus grande qualité des produits et services fournis.
              </li>
              <li><b>MonResto.click est une marketplace nouvelle génération</b>, 
                dont l’ergonomie a été pensée pour fonctionner sur téléphone portable (mobile first). La navigation est donc particulièrement fluide et intuitive et l’affichage des restaurants optimisé.
              </li>
              <li><b>Le moyen de paiement en ligne utilise la technologie STRIPE.</b>
                Vos coordonnées bancaires sont cryptées et transmises directement via une connexion sécurisée à la plateforme de paiement. MonResto.Click vous garantit un achat sans risque et en toute confiance. Notre plateforme Stripe utilise la technologie SSL (cryptage en 128 bits) pour garantir une transaction complètement sécurisée lors du paiement.
              </li>
              <li><b>La plateforme est évolutive,</b>
                divers critères affinent ou affineront bientôt votre recherche comme la distance, la popularité, la nouveauté, les avis clients, les offres en cours, la possibilité d’ajouter des restaurants à une liste de favoris…
              </li>
              <li><b>Enfin MonResto.click est concerné par la situation difficile des restaurateurs</b>
                et s’inscrit dans une démarche Resto-friendly. Nos frais de commission sont les plus bas du marché. Nous souhaitons les aider à récupérer un chiffre d'affaires sans impacter leur marge. Lorsque vous commandez via MonResto.click vous préservez la rentabilité du restaurateur.
              </li>
              
            </ul>
<!--            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aliquid asperiores dignissimos magnam neque odio odit placeat quia recusandae, sit? Aspernatur culpa hic maiores minima molestias obcaecati quam repellat reprehenderit.-->
          </div>
        </el-collapse-item>
        <el-collapse-item title="Comment commander sur Monresto.click?">
          <div>
            <ul>
              <li><b>Pour commander</b>
                vous devez posséder un compte client.
              </li>
              <li><b>Si vous n’en avez pas,</b>
                cliquez sur l’onglet « créer mon compte », et complétez le formulaire. Vous devez fournir notamment une adresse électronique valide afin de pouvoir activer votre compte.
              </li>
              <li><b>Si vous avez déjà un compte actif,</b>
                connectez-vous simplement avec votre email et votre mot de passe.
              </li>
              <li><b>Pour choisir un restaurant,</b>
                sélectionnez votre ville et/ou un style de restauration. Après avoir fait votre choix de restaurant, consultez les différentes prestations et services proposés par le restaurant et ajoutez les produits souhaités à votre panier.
              </li>
              <li><b>Vérifiez votre panier</b>
                afin de rectifier d’éventuelles erreurs de saisie, procédez au choix de mise à disposition de votre commande parmi ceux proposés par le restaurant, puis valider votre commande.
              </li>
            </ul> 
          </div>
        </el-collapse-item>
        <el-collapse-item title="Comment savoir si ma commande a bien été prise en compte ?">
          <div>
            <ul>
              <li><b>Après avoir passé votre commande</b>
                en ligne, vous recevez un e-mail de confirmation sur votre boite mail. Conservez bien cet e-mail et la référence de votre commande.
              </li>
              <li><b>Sur la plateforme vous disposez d’un suivi de commande. </b>
                Dès la prise en charge de la commande par le restaurateur, un message vous informe que votre « commande est en cours de préparation ».
              </li>
              <li><b>Il est possible que l’e-mail de confirmation</b>
                soit dans vos spams, pensez à vérifier.
              </li>
            </ul> 
          </div>
        </el-collapse-item>
        <el-collapse-item title="Puis je annuler ma commande ?">
          <div>
            <ul>
              <li><b>En raison du nombre d’étapes vous permettant le contrôle de votre panier </b>
                avant la validation finale, vous ne pouvez pas annuler votre commande via la plateforme MonResto.click.
              </li>
              <li><b>Néanmoins vous pouvez, le cas échéant, tenter d’appeler</b>
                très rapidement et directement le restaurateur pour exposer votre problème en cas d’erreur non rectifiée au moment de la commande. Vous trouverez son numéro de téléphone sur sa page monresto.click comme sur l’e-mail de confirmation.
              </li>
              <li><b>Ce délai rapide, sous-entend</b>
                que l’appel a eu lieu avant le message «commande en préparation » sur l’interface afin de ne pas pénaliser le restaurateur.
              </li>
              <li><b>Le restaurateur qui ne peut pas annuler </b>
                ou rembourser la totalité de la commande, fera néanmoins son possible pour vous proposer une solution amiable selon ses propres conditions générales de vente.
              </li>
              <li><b>Si aucun accord à l’amiable n’a pu aboutir </b>
                avec le restaurateur vous trouverez au sein des CGV (annulation ou erreur de commande) un lien vers un formulaire de demande de remboursement à nous faire parvenir sous quinze jours maximum.
              </li>
              <li><b>Notez également que conformément à l'article L.221-28 4°</b>
                du code de la consommation, lors de la réception des produits, le consommateur ne peut exercer le droit de rétractation prévu sur des produits périssables.
              </li>
            </ul> 
          </div>
        </el-collapse-item>
        <el-collapse-item title="Que faire si je suis intolérant ou allergique à certains aliments ?">
          <div>
            <ul>
              <li><b>Lisez bien le descriptif réalisé</b>
                par le restaurateur pour faire un choix responsable.
              </li>
              <li><b>Si c’est une allergie potentiellement grave</b>
                ne commandez pas sans appeler le restaurateur. Pour une intolérance sans conséquence sérieuse sur votre santé, vous pouvez néanmoins passer votre commande et informer le restaurateur via la rubrique « note » lors de votre commande. </li>
              <li><b>Attention si le restaurateur ne peut satisfaire</b>
                à cette exigence spécifique il sera contraint d’annuler la totalité de la commande.
              </li>
            </ul> 
          </div>
        </el-collapse-item>
        <el-collapse-item title="J’ai oublié mon mot de passe ?">
          <div>
            <ul>
              <li><b>Vous avez déjà créé un compte</b>
                mais vous ne vous rappelez plus de votre mot de passe?
              </li>
              <li><b>Cliquez sur 'Se connecter',</b>
                en haut à droite de l'écran. Ensuite cliquez sur 'Mot de passe oublié', tapez votre adresse e-mail. Un e-mail vous permettant de réinitialiser votre mot de passe vous sera alors envoyé automatiquement.
              </li>
            </ul> 
          </div>
        </el-collapse-item>
        <el-collapse-item title="Je ne suis pas satisfait de la prestation qui est responsable ?">
          <div>

            <ul>
              <li><b>Nous ne sommes qu’un intermédiaire,</b>
                nous transmettons votre commande au restaurant choisi. Même si nous nous efforçons de sélectionner des professionnels pour leur engagement qualité globale nous ne saurions avoir une responsabilité dans la qualité des mets ni sur la rapidité de la livraison ou la qualité de l’accueil.
              </li>
              <li><b>Si vous n'êtes pas satisfait de votre commande,</b>
                vous pouvez contacter le restaurant via le numéro de téléphone qui se trouve sur leur page d’accueil depuis notre plateforme ou dans l'e-mail de confirmation de commande.
              </li>
              <li><b>Vous pouvez également prendre contact avec notre Service Client info@monresto.click.</b>
                Nous transmettrons alors votre réclamation au restaurant en lui demandant de vous contacter le plus rapidement possible, nous conserverons une trace de cette réclamation.
              </li>
            </ul> 
          </div>
        </el-collapse-item>
        <el-collapse-item title="Comment fonctionne l’ordre de présentation des restaurants ?">
          <div>
            <ul>
              <li><b>La présentation est pour l’heure tout à fait aléatoire</b>
                et peut changer en cours de journée, elle évoluera par la suite selon divers critères comme la distance par rapport au client, la moyenne des notes ou la popularité.
              </li>
              <li><b>Un restaurant nouvellement inscrit</b>
                
                bénéficie d’une mise en avant temporaire.
              </li>
              <li><b>Des filtres vous permettent d’affiner l’ordre</b>
                de présentation selon vos souhaits spécifiques.
              </li>
            </ul>

          </div>
        </el-collapse-item>
        <el-collapse-item title="Ma question n’est pas dans la liste ?">
          <div>
            <b>Contacter le service client à</b> <a href="mailto:info@monresto.click">info@monresto.click</a> et exposez votre problème, nous prendrons contact avec vous pour répondre à votre question dans les meilleurs délais
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>

</template>

<script>
export default {
  name: "faq_restaurateur",
  data() {
    return {
      open: false
    }
  }
}
</script>
<style>
</style>
<style scoped>
.faq { display: flex; justify-content: space-between; flex-direction: column; padding-bottom: 0px; background: #fbfbfb }
.faq .t { font-size: 18px; padding: 16px; font-weight: 500; display: flex; justify-content: space-between; align-items: center }

.faq[data-open] .t i { transform: rotate(90deg) }
.faq .f { display: none }
.faq[data-open] .f { display: block }


@media only screen and (min-width: 800px) {
  .faq { padding: 100px 20%; flex-direction: row }
  .faq .t { font-size: 2.5vw; padding: 0; display: block; font-weight: bold; }
  .faq .t i { display: none }
  .faq .f { width: 70%; display: block }
}
</style>